/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import noop from 'lodash/noop';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'redux-first-router';
import { trackGtagEvent } from 'utils';

import { QUERIES, SearchInput, useInitialPaginationFetch } from '@marapp/earth-shared';

import { recordFilterSelection, recordSearchPlaces } from '../../analytics/gtag';
import { useAuth0 } from '../../auth/auth0';
import BackToLocation from '../../components/back-to-location';
import FilterBy from '../../components/filter-by';
import SidebarLayoutSearch from '../../components/sidebar/sidebar-layout-search';
import { useLocations } from '../../fetchers';
import { hasFilters } from '../../utils/filters';
import PlacesSearchResults from './search-results';

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.grey['600'],
  },
}));

interface IProps {
  selected: boolean;
  children: any;
  panel?: string;
  panelExpanded?: boolean;
  search?: any;
  results?: any;
  nextPageCursor?: string;
  locationName?: string;
  locationOrganization?: string;
  setSidebarOpen?: (value: boolean) => void;
  setSidebarPanelExpanded?: (value: boolean) => void;
  resetMap?: () => {};
  setIndexesSelected?: (value: any) => {};
  setPlacesSearch?: (value: any) => {};
  setPlacesSearchOpen?: (value: boolean) => {};
  currentFilters: { [key: string]: string[] };
  setCurrentFilters: (value: { [key: string]: string[] }) => {};
}

interface ISelectedFilters {
  filters: {
    type: string[];
  };
}

interface ISelectedFilters {
  filters: {
    type: string[];
  };
}

const Places = (props: IProps) => {
  const { t } = useTranslation();
  const {
    panelExpanded,
    search,
    locationName,
    locationOrganization,
    resetMap,
    setPlacesSearch,
    setSidebarPanelExpanded,
    setSidebarOpen,
    setPlacesSearchOpen,
    selected,
    children,
    currentFilters,
    setCurrentFilters,
  } = props;
  const classes = useStyles();
  const [availableFilters, setAvailableFilters] = useState(null);
  const { selectedGroup } = useAuth0();
  const fetchedLocations = useLocations(
    QUERIES.LOCATION.getFiltered(search.search, search.filters)
  );
  const { data: placesData, meta, awaitMore, nextPage, isValidating } = fetchedLocations;

  const { totalResults } = useInitialPaginationFetch(search, fetchedLocations, (newFetch) => {
    setAvailableFilters(newFetch?.meta?.filters);
  });

  const hasSearchTerm = !!search.search;
  const showX = selected || hasSearchTerm;
  const showFilter = !selected || panelExpanded;
  const withFilters = hasFilters(search.filters);
  const showResults = hasSearchTerm || withFilters;
  const showBack = selected && panelExpanded && showResults;
  const onLocationPage = selected && panelExpanded && showResults;
  const onHomepage = !selected && showResults;
  const showSearchResults = onLocationPage || onHomepage;

  useEffect(() => {
    if (showSearchResults) {
      setPlacesSearch({ search: search.previousSearch });
      trackGtagEvent('view_search_results', { search_term: search.previousSearch });
      resetMap();
    }
  }, []);

  // Don't set setSidebarPanelExpanded when on /earth
  const expandConditionally = selected ? () => setSidebarPanelExpanded(true) : noop;

  const handleChange = (newValue) => {
    setPlacesSearch({ search: newValue });
    trackGtagEvent('search', { search_term: newValue });
    recordSearchPlaces(newValue);
  };

  const handleBack = () => {
    if (selected) {
      setPlacesSearch({ search: locationName });
    }
    setSidebarPanelExpanded(false);
  };

  const handleReset = () => {
    setPlacesSearch({ search: '', filters: currentFilters }); // Set current selected places filters on search when search is reset
    setSidebarPanelExpanded(false);
    resetMap();
    push('/earth');
  };

  // Update current filters on <FilterBy /> changes and set places search
  const onCurrentFilterChange = (selectedFilters: ISelectedFilters) => {
    setCurrentFilters(selectedFilters.filters);
    setPlacesSearch(selectedFilters);

    if (selectedFilters.filters && selectedFilters.filters.type) {
      recordFilterSelection('Place', selectedFilters.filters.type);
    }
  };

  return (
    <SidebarLayoutSearch
      fixedContent={
        <>
          <Box p={2} className={classes.searchContainer}>
            <SearchInput
              value={search.search}
              placeholder={t('search places')}
              onChange={handleChange}
              onReset={handleReset}
              onFocus={expandConditionally}
              showResetButton={showX}
            />
          </Box>
          {showFilter && (
            <FilterBy
              type="Place"
              open={search.open}
              onOpenToggle={setPlacesSearchOpen}
              onChange={onCurrentFilterChange}
              filters={search.filters}
              availableFilters={availableFilters || {}}
              renderLive={true}
              defaultExpandedFilters={currentFilters}
            />
          )}
          {showBack && (
            <BackToLocation
              onClick={handleBack}
              location={locationName}
              organization={locationOrganization}
            />
          )}
        </>
      }
    >
      <Box mt={1}>
        {showSearchResults && totalResults !== undefined ? (
          <PlacesSearchResults
            data={placesData}
            awaitMore={awaitMore}
            nextPage={nextPage}
            group={selectedGroup}
            isValidating={isValidating}
            setPlacesSearch={setPlacesSearch}
            search={search.search}
            filters={search.filters}
            setSidebarPanelExpanded={setSidebarPanelExpanded}
            setSidebarOpen={setSidebarOpen}
            totalResults={totalResults}
          />
        ) : (
          children
        )}
      </Box>
    </SidebarLayoutSearch>
  );
};

export default Places;
