import { format } from 'd3-format';

import { formatKM2 } from '../../../widget/utils';
import { IMetricTableData, IMetricTableDataValue } from '../config';

const getDataValue = (value: number): IMetricTableDataValue => {
  const f = formatKM2(value);

  return {
    value,
    displayValue: value ? `${format(f)(value)} km²` : '-',
  };
};

export const handler = (metricData: any): IMetricTableData[] => {
  /**
   * 1. Extract all the possible years from received data
   */
  const years = new Set();

  metricData.forEach((metricInfo) => {
    const {
      metric: { year_data },
    } = metricInfo;

    year_data && Object.keys(year_data).forEach((year) => years.add(year));
  });

  return metricData.map((metricInfo) => {
    const {
      id,
      location: { name },
      metric: { year_data },
    } = metricInfo;

    const mappedMetricInfo = {
      id,
      location: name,
    };

    if (year_data) {
      years.forEach((year: string) => {
        const value = year_data[year];

        mappedMetricInfo[year] = getDataValue(value);
      });
    }

    return mappedMetricInfo;
  });
};
