import Collapse from '@material-ui/core/Collapse';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Chart } from '@marapp/earth-shared';

import WidgetNoDataComponent from '../../../../components/widget/no-data';
import WidgetTemplateText from '../../templateText';
import { IWidgetTemplate } from '../model';

const Fire = (props: IWidgetTemplate) => {
  const { chart, config, expanded, noData, template } = props;
  const { t } = useTranslation();

  if (noData) {
    return (
      <WidgetNoDataComponent expanded={expanded}>
        {t('No fire activity has been detected for this location.')}
      </WidgetNoDataComponent>
    );
  }

  return (
    <>
      <WidgetTemplateText expanded={expanded} template={template} />

      {/* Chart */}
      {!!chart.length && (
        <Collapse in={!!expanded}>
          <Chart data={chart} config={config} />
        </Collapse>
      )}
    </>
  );
};

export default Fire;
