import { format } from 'd3-format';
import React from 'react';

import WidgetTooltip from '../../../../components/widget/tooltip';
import { IPlace } from '../../../../modules/places/model';
import { IWidgetConfig } from '../../../../modules/widget/model';
import { replace } from '../../utils';
import { EviMetric } from './model';

interface EviConfig {
  metric: EviMetric;
}

export const CONFIG = {
  parse: ({ metric }: EviConfig, params, widgetConfig: IWidgetConfig, place: IPlace) => {
    if (!metric?.year_data) {
      return {
        noData: true,
        chart: [],
        template: '',
      };
    }
    const { sentence } = widgetConfig;

    const { year_data, trend_line } = metric;
    const { gradient, y_intercept } = trend_line;

    const minYear = Math.min(...year_data.map((y) => Number(y.year)));
    const chartData = year_data.map((data) => ({
      ...data,
      bestFit: gradient * (data.year - minYear) + y_intercept,
    }));
    const yearlyPercentageChange = (gradient * 100) / y_intercept;

    return {
      chart: chartData,
      template: replace(
        sentence.default,
        {
          numYears: year_data.length,
          location: place.name,
          condition: yearlyPercentageChange >= 0 ? 'upwards' : 'downwards',
          yearlyPercentageChange: `${format(',.2f')(yearlyPercentageChange)}%`,
        },
        {},
        {
          className: 'text-bold',
        }
      ),
      config: {
        type: 'line',
        xKey: 'year',
        margin: { top: 10, right: 10, left: 40, bottom: 0 },
        yKeys: {
          lines: {
            mean: {
              stroke: '#31a354',
              dot: true,
            },
            bestFit: {
              stroke: '#728979',
              dot: false,
              strokeWidth: 1,
              type: 'line',
              dataKey: 'bestFit',
              strokeDasharray: '6 4',
              activeDot: false,
            },
          },
        },
        xAxis: {
          dataKey: 'year',
          axisLine: {
            stroke: '#CCC',
            strokeWidth: 1,
          },
          angle: -45,
          dx: -10,
          dy: 6,
          tick: { fontSize: 11, fill: '#FFF', fontWeight: 500 },
          interval: 0,
        },
        yTitle: 'Mean EVI',
        yAxis: {
          domain: ['auto', 'auto'],
          interval: 0,
          axisLine: {
            stroke: '#CCC',
            strokeWidth: 1,
          },
        },
        cartesianGrid: {
          vertical: true,
          horizontal: false,
          strokeDasharray: '2 2',
        },
        tooltip: {
          cursor: false,
          content: (
            <WidgetTooltip
              settings={[
                { key: 'year' },
                {
                  label: 'Mean EVI:',
                  key: 'mean',
                  format: (value) => {
                    return `${format(',.3f')(value)}`;
                  },
                },
              ]}
            />
          ),
        },
      },
    };
  },
};

export default CONFIG;
