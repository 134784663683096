/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

export interface ILayerGroup {
  dataset: string;
  description: string;
  layers: ILayer[];
  legendType: 'yearpicker' | 'yeardatepicker';
  name: string;
  slug: string;
  visibility: boolean;
}

export interface ILayer {
  id: string;
  slug?: string;
  name?: string;
  description: string;
  type?: LayerType;
  organization?: string;
  provider?: LayerProvider;
  category: LayerCategory[];
  tags: string[];
  legendConfig: object;
  layerConfig: ILayerConfig;
  STACConfig?: ISTACConfig;
  decodeFunction?: any;
  interactionConfig: any;
  published: boolean;
  licenseRestriction: boolean;
  createdAt: Date;
  updatedAt: Date;
  version: number;
  references?: ILayer[];
  source?: any;
  paramsConfig?: any;
  sqlConfig?: any;
  decodeConfig?: any;
  timelineConfig?: any;
  render?: any;
}

export interface ILayerRaw extends ILayer {
  config: any;
}

interface ILayerConfig {
  paramsConfig: any;
  sqlConfig: any;
  decodeConfig: any;
  timelineConfig: any;
  type: LayerType;
  layers: ILayer[];
  body: any;
}

interface ISTACConfig {
  collection?: string;
  dataType?: string;
  asset?: string;
  startDate?: string;
  endDate?: string;
  STAC_API_URL?: string;
  TILER_URL?: string;
  STACLegendItems?: any[];
}

export enum LayerCategory {
  BOUNDARIES = 'Boundaries',
  ADMIN_AREAS = 'Boundaries/Administrative Areas',
  BIOGEOGRAPHIC_REGIONS = 'Boundaries/Biogeographical Regions',
  PROTECTED_CONSERVED_AREAS = 'Protected and Conserved Areas',
  LAND_COVER_AND_USE = 'Land Cover and Land Use',
  WATER = 'Water',
  WATER_FRESH = 'Water/Freshwater',
  WATER_OCEANS = 'Water/Oceans',
  CLIMATE_AND_CARBON = 'Climate and Carbon',
  CLIMATE_CHANGE_MITIGATION = 'Climate and Carbon/Climate Change Mitigation',
  CLIMATE_CHANGE_ADAPTATION = 'Climate and Carbon/Climate Change Adaptation',
  NATURE_BASED_SOLUTIONS = 'Nature Based Solutions',
  SUSTAINABLE_DEVELOPMENT = 'Sustainable Development',
  NATURAL_HAZARDS = 'Natural Hazards',
  BIODIVERSITY = 'Biodiversity',
  BIODIVERSITY_SPECIES = 'Biodiversity/Species',
  BIODIVERSITY_ECOSYSTEMS = 'Biodiversity/Ecosystem',
  BIODIVERSITY_GENES = 'Biodiversity/Genes',
  ECOSYSTEM_SERVICES = 'Ecosystem Services',
  HUMAN_IMPACT = 'Human Impact',
  AGRICULTURE = 'Agriculture',
  BUILT_ENVIRONMENT = 'Built Environment',
  RESTORATION = 'Restoration',
  SOCIETY = 'Society',
  SOCIOECONOMIC = 'Society/Socioeconomic',
  PUBLIC_HEALTH = 'Society/Public Health',
  SOCIAL_BEHAVIOR = 'Society/Social Behaviour',

  POLICY = 'Policy',
  KM_GBF = 'Policy/Global Biodiversity Framework',

  KM_GBF_GOAL_A = 'Policy/Global Biodiversity Framework/Goal A',
  KM_GBF_GOAL_A_HEADLINE = 'Policy/Global Biodiversity Framework/Goal A/Headline Indicators',
  KM_GBF_GOAL_A_COMPONENT = 'Policy/Global Biodiversity Framework/Goal A/Component Indicators',
  KM_GBF_GOAL_A_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Goal A/Complementary Indicators',

  KM_GBF_GOAL_B = 'Policy/Global Biodiversity Framework/Goal B',
  KM_GBF_GOAL_B_HEADLINE = 'Policy/Global Biodiversity Framework/Goal B/Headline Indicators',
  KM_GBF_GOAL_B_COMPONENT = 'Policy/Global Biodiversity Framework/Goal B/Component Indicators',
  KM_GBF_GOAL_B_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Goal B/Complementary Indicators',

  KM_GBF_GOAL_C = 'Policy/Global Biodiversity Framework/Goal C',
  KM_GBF_GOAL_C_HEADLINE = 'Policy/Global Biodiversity Framework/Goal C/Headline Indicators',
  KM_GBF_GOAL_C_COMPONENT = 'Policy/Global Biodiversity Framework/Goal C/Component Indicators',
  KM_GBF_GOAL_C_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Goal C/Complementary Indicators',

  KM_GBF_GOAL_D = 'Policy/Global Biodiversity Framework/Goal D',
  KM_GBF_GOAL_D_HEADLINE = 'Policy/Global Biodiversity Framework/Goal D/Headline Indicators',
  KM_GBF_GOAL_D_COMPONENT = 'Policy/Global Biodiversity Framework/Goal D/Component Indicators',
  KM_GBF_GOAL_D_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Goal D/Complementary Indicators',

  KM_GBF_TARGET_1 = 'Policy/Global Biodiversity Framework/Target 01',
  KM_GBF_TARGET_1_HEADLINE = 'Policy/Global Biodiversity Framework/Target 01/Headline Indicators',
  KM_GBF_TARGET_1_COMPONENT = 'Policy/Global Biodiversity Framework/Target 01/Component Indicators',
  KM_GBF_TARGET_1_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 01/Complementary Indicators',

  KM_GBF_TARGET_2 = 'Policy/Global Biodiversity Framework/Target 02',
  KM_GBF_TARGET_2_HEADLINE = 'Policy/Global Biodiversity Framework/Target 02/Headline Indicators',
  KM_GBF_TARGET_2_COMPONENT = 'Policy/Global Biodiversity Framework/Target 02/Component Indicators',
  KM_GBF_TARGET_2_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 02/Complementary Indicators',

  KM_GBF_TARGET_3 = 'Policy/Global Biodiversity Framework/Target 03',
  KM_GBF_TARGET_3_HEADLINE = 'Policy/Global Biodiversity Framework/Target 03/Headline Indicators',
  KM_GBF_TARGET_3_COMPONENT = 'Policy/Global Biodiversity Framework/Target 03/Component Indicators',
  KM_GBF_TARGET_3_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 03/Complementary Indicators',

  KM_GBF_TARGET_4 = 'Policy/Global Biodiversity Framework/Target 04',
  KM_GBF_TARGET_4_HEADLINE = 'Policy/Global Biodiversity Framework/Target 04/Headline Indicators',
  KM_GBF_TARGET_4_COMPONENT = 'Policy/Global Biodiversity Framework/Target 04/Component Indicators',
  KM_GBF_TARGET_4_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 04/Complementary Indicators',

  KM_GBF_TARGET_5 = 'Policy/Global Biodiversity Framework/Target 05',
  KM_GBF_TARGET_5_HEADLINE = 'Policy/Global Biodiversity Framework/Target 05/Headline Indicators',
  KM_GBF_TARGET_5_COMPONENT = 'Policy/Global Biodiversity Framework/Target 05/Component Indicators',
  KM_GBF_TARGET_5_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 05/Complementary Indicators',

  KM_GBF_TARGET_6 = 'Policy/Global Biodiversity Framework/Target 06',
  KM_GBF_TARGET_6_HEADLINE = 'Policy/Global Biodiversity Framework/Target 06/Headline Indicators',
  KM_GBF_TARGET_6_COMPONENT = 'Policy/Global Biodiversity Framework/Target 06/Component Indicators',
  KM_GBF_TARGET_6_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 06/Complementary Indicators',

  KM_GBF_TARGET_7 = 'Policy/Global Biodiversity Framework/Target 07',
  KM_GBF_TARGET_7_HEADLINE = 'Policy/Global Biodiversity Framework/Target 07/Headline Indicators',
  KM_GBF_TARGET_7_COMPONENT = 'Policy/Global Biodiversity Framework/Target 07/Component Indicators',
  KM_GBF_TARGET_7_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 07/Complementary Indicators',

  KM_GBF_TARGET_8 = 'Policy/Global Biodiversity Framework/Target 08',
  KM_GBF_TARGET_8_HEADLINE = 'Policy/Global Biodiversity Framework/Target 08/Headline Indicators',
  KM_GBF_TARGET_8_COMPONENT = 'Policy/Global Biodiversity Framework/Target 08/Component Indicators',
  KM_GBF_TARGET_8_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 08/Complementary Indicators',

  KM_GBF_TARGET_9 = 'Policy/Global Biodiversity Framework/Target 09',
  KM_GBF_TARGET_9_HEADLINE = 'Policy/Global Biodiversity Framework/Target 09/Headline Indicators',
  KM_GBF_TARGET_9_COMPONENT = 'Policy/Global Biodiversity Framework/Target 09/Component Indicators',
  KM_GBF_TARGET_9_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 09/Complementary Indicators',

  KM_GBF_TARGET_10 = 'Policy/Global Biodiversity Framework/Target 10',
  KM_GBF_TARGET_10_HEADLINE = 'Policy/Global Biodiversity Framework/Target 10/Headline Indicators',
  KM_GBF_TARGET_10_COMPONENT = 'Policy/Global Biodiversity Framework/Target 10/Component Indicators',
  KM_GBF_TARGET_10_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 10/Complementary Indicators',

  KM_GBF_TARGET_11 = 'Policy/Global Biodiversity Framework/Target 11',
  KM_GBF_TARGET_11_HEADLINE = 'Policy/Global Biodiversity Framework/Target 11/Headline Indicators',
  KM_GBF_TARGET_11_COMPONENT = 'Policy/Global Biodiversity Framework/Target 11/Component Indicators',
  KM_GBF_TARGET_11_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 11/Complementary Indicators',

  KM_GBF_TARGET_12 = 'Policy/Global Biodiversity Framework/Target 12',
  KM_GBF_TARGET_12_HEADLINE = 'Policy/Global Biodiversity Framework/Target 12/Headline Indicators',
  KM_GBF_TARGET_12_COMPONENT = 'Policy/Global Biodiversity Framework/Target 12/Component Indicators',
  KM_GBF_TARGET_12_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 12/Complementary Indicators',

  KM_GBF_TARGET_13 = 'Policy/Global Biodiversity Framework/Target 13',
  KM_GBF_TARGET_13_HEADLINE = 'Policy/Global Biodiversity Framework/Target 13/Headline Indicators',
  KM_GBF_TARGET_13_COMPONENT = 'Policy/Global Biodiversity Framework/Target 13/Component Indicators',
  KM_GBF_TARGET_13_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 13/Complementary Indicators',

  KM_GBF_TARGET_14 = 'Policy/Global Biodiversity Framework/Target 14',
  KM_GBF_TARGET_14_HEADLINE = 'Policy/Global Biodiversity Framework/Target 14/Headline Indicators',
  KM_GBF_TARGET_14_COMPONENT = 'Policy/Global Biodiversity Framework/Target 14/Component Indicators',
  KM_GBF_TARGET_14_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 14/Complementary Indicators',

  KM_GBF_TARGET_15 = 'Policy/Global Biodiversity Framework/Target 15',
  KM_GBF_TARGET_15_HEADLINE = 'Policy/Global Biodiversity Framework/Target 15/Headline Indicators',
  KM_GBF_TARGET_15_COMPONENT = 'Policy/Global Biodiversity Framework/Target 15/Component Indicators',
  KM_GBF_TARGET_15_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 15/Complementary Indicators',

  KM_GBF_TARGET_16 = 'Policy/Global Biodiversity Framework/Target 16',
  KM_GBF_TARGET_16_HEADLINE = 'Policy/Global Biodiversity Framework/Target 16/Headline Indicators',
  KM_GBF_TARGET_16_COMPONENT = 'Policy/Global Biodiversity Framework/Target 16/Component Indicators',
  KM_GBF_TARGET_16_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 16/Complementary Indicators',

  KM_GBF_TARGET_17 = 'Policy/Global Biodiversity Framework/Target 17',
  KM_GBF_TARGET_17_HEADLINE = 'Policy/Global Biodiversity Framework/Target 17/Headline Indicators',
  KM_GBF_TARGET_17_COMPONENT = 'Policy/Global Biodiversity Framework/Target 17/Component Indicators',
  KM_GBF_TARGET_17_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 17/Complementary Indicators',

  KM_GBF_TARGET_18 = 'Policy/Global Biodiversity Framework/Target 18',
  KM_GBF_TARGET_18_HEADLINE = 'Policy/Global Biodiversity Framework/Target 18/Headline Indicators',
  KM_GBF_TARGET_18_COMPONENT = 'Policy/Global Biodiversity Framework/Target 18/Component Indicators',
  KM_GBF_TARGET_18_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 18/Complementary Indicators',

  KM_GBF_TARGET_19 = 'Policy/Global Biodiversity Framework/Target 19',
  KM_GBF_TARGET_19_HEADLINE = 'Policy/Global Biodiversity Framework/Target 19/Headline Indicators',
  KM_GBF_TARGET_19_COMPONENT = 'Policy/Global Biodiversity Framework/Target 19/Component Indicators',
  KM_GBF_TARGET_19_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 19/Complementary Indicators',

  KM_GBF_TARGET_20 = 'Policy/Global Biodiversity Framework/Target 20',
  KM_GBF_TARGET_20_HEADLINE = 'Policy/Global Biodiversity Framework/Target 20/Headline Indicators',
  KM_GBF_TARGET_20_COMPONENT = 'Policy/Global Biodiversity Framework/Target 20/Component Indicators',
  KM_GBF_TARGET_20_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 20/Complementary Indicators',

  KM_GBF_TARGET_21 = 'Policy/Global Biodiversity Framework/Target 21',
  KM_GBF_TARGET_21_HEADLINE = 'Policy/Global Biodiversity Framework/Target 21/Headline Indicators',
  KM_GBF_TARGET_21_COMPONENT = 'Policy/Global Biodiversity Framework/Target 21/Component Indicators',
  KM_GBF_TARGET_21_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 21/Complementary Indicators',

  KM_GBF_TARGET_22 = 'Policy/Global Biodiversity Framework/Target 22',
  KM_GBF_TARGET_22_HEADLINE = 'Policy/Global Biodiversity Framework/Target 22/Headline Indicators',
  KM_GBF_TARGET_22_COMPONENT = 'Policy/Global Biodiversity Framework/Target 22/Component Indicators',
  KM_GBF_TARGET_22_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 22/Complementary Indicators',

  KM_GBF_TARGET_23 = 'Policy/Global Biodiversity Framework/Target 23',
  KM_GBF_TARGET_23_HEADLINE = 'Policy/Global Biodiversity Framework/Target 23/Headline Indicators',
  KM_GBF_TARGET_23_COMPONENT = 'Policy/Global Biodiversity Framework/Target 23/Component Indicators',
  KM_GBF_TARGET_23_COMPLEMENTARY = 'Policy/Global Biodiversity Framework/Target 23/Complementary Indicators',
}

export enum LayerType {
  raster = 'raster',
  vector = 'vector',
  geojson = 'geojson',
  group = 'group',
  video = 'video',
}

export enum LayerProvider {
  cartodb = 'cartodb',
  gee = 'gee',
  mapbox = 'mapbox',
  leaflet = 'leaflet',
}

export enum MapType {
  ELSA = 'ELSA',
  MLOD = 'MLOD',
}
