import { format } from 'd3-format';

import { IMetricTableData } from '../config';

const getDataValue = (area, percentage) => {
  return {
    children: {
      area: {
        value: area,
        displayValue: `${format(',.0f')(area)} km²`,
      },
      '%': {
        value: percentage,
        displayValue: `${percentage}%`,
      },
    },
  };
};

const formatValueKm2 = (value, total) => {
  let round;

  if (total >= 100000) {
    round = 100;
  } else if (total >= 10000 && total < 100000) {
    round = 10;
  } else {
    round = 1;
  }

  const t = Math.round(total * round) / round;

  return Math.round((t * Math.round(value)) / 100 / round) * round;
};

export const handler = (metricData: any): IMetricTableData[] => {
  return metricData.map((metricInfo) => {
    const {
      id,
      location: { name },
      metric: {
        marine_area_km2,
        marine_perc,
        terrestrial_area_km2,
        terrestrial_perc,
        unprotected_area_km2,
        unprotected_perc,
      },
    } = metricInfo;

    const totalkm2 = unprotected_area_km2 + terrestrial_area_km2 + marine_area_km2;
    const unprotectedkm2 = formatValueKm2(unprotected_perc, totalkm2);
    const protectedkm2 = formatValueKm2(marine_perc + terrestrial_perc, totalkm2);

    return {
      id,
      location: name,
      Protected: getDataValue(protectedkm2, Math.round(marine_perc) + Math.round(terrestrial_perc)),
      Unprotected: getDataValue(unprotectedkm2, Math.round(unprotected_perc)),
    };
  });
};
