/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'redux-first-router';
import Link from 'redux-first-router-link';

import { ErrorMessages } from '@marapp/earth-shared';

import { resetLayersActive, setLayersActive, toggleLayer } from '../../modules/layers/actions';
import { ILayer } from '../../modules/layers/model';
import { resetMap, setLocationHighlight, setMapBounds } from '../../modules/map/actions';
import { EarthRoutes, IRouter } from '../../modules/router/model';
import { fetchAnalyses } from '../../services/AnalysisService';
import PlacesService from '../../services/PlacesService';
import { PreviewELSALayer } from './utils';

interface IProps {
  privateGroups: string[];
  group: string[];
  router?: IRouter;
  resetMap?: (payload?: any) => void;
  resetLayersActive?: (payload?: any) => void;
  setLocationHighlight?: (payload?: any) => void;
  setMapBounds?: (payload?: any) => void;
  setMapLayer?: (layer?: ILayer) => void;
  toggleLayer?: (payload?: any) => void;
}

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.grey['600'],
  },
  link: {
    '& a': {
      textDecoration: 'underline',
    },
  },
  hidden: {
    display: 'none',
  },
}));

function WithData(props: IProps) {
  const {} = props;
  const {
    privateGroups,
    router,
    resetMap,
    resetLayersActive,
    setMapBounds,
    setMapLayer,
    setLocationHighlight,
    toggleLayer,
  } = props;
  const { prev } = router;
  const { t } = useTranslation('unbl');
  const classes = useStyles();
  const privateGroupsFiltered = privateGroups.filter((item) => item.toLowerCase() !== 'unbl');
  // special case to remove UNBL from list
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [analysisAvailable, setAnalysisAvailable] = useState(null);
  const [analysisInWorkspace, setAnalysisInWorkspace] = useState(null);
  const [analysis, setAnalysis] = useState(null);
  const [formComplete, setFormComplete] = useState(false);
  const [serverErrors, setServerErrors] = useState(null);
  const { control, watch, setValue } = useFormContext();

  const hasMultiplePrivateGroups = privateGroupsFiltered.length > 1;
  const defaultPrivateGroup = privateGroupsFiltered[0];

  useEffect(() => {
    if (!analysisAvailable) {
      fetchAnalysisAvailable();
      return;
    }
    if (selectedGroup == null) {
      onChangeGroup(defaultPrivateGroup);
    }
  }, [privateGroups, analysisAvailable]);
  // make sure this doesn't include privateGroupsFiltered, otherwise we get an effect loop

  useEffect(() => {
    setFormComplete(selectedGroup && analysisAvailable && analysis);
  }, [selectedGroup, analysisAvailable, analysis]);

  const fetchAnalysisAvailable = async () => {
    try {
      const { data } = await fetchAnalyses({
        group: privateGroupsFiltered.join(','),
        type: 'ELSA',
      });
      setAnalysisAvailable(data);
    } catch (error) {
      if (error?.data) {
        setServerErrors(error.data.errors);
        console.error(error.data.errors);
      } else {
        setServerErrors([{ detail: error || t('Unable to start analysis') }]);
        console.error(error);
      }
    }
  };

  const onChangeGroup = (value) => {
    setSelectedGroup(value);
    setValue('organization', value);
    setAnalysis(undefined);
    if (analysisAvailable) {
      setAnalysisInWorkspace(analysisAvailable.filter((a) => a.organization === value));
    } else {
      resetMap();
    }
  };

  const onChangeAnalysis = (analysisId) => {
    const analysis = analysisAvailable.find((a) => a.id === analysisId);
    resetLayersActive();

    if (!analysis) {
      console.error('unable to match analysisId', analysisId);
    }
    setAnalysis(analysis);
    if (analysis.config.location) {
      fetchLocation(analysis.config?.location);

      if (analysis.config.adopted_map) {
        const adoptedMapLayer = PreviewELSALayer(
          `elsa_adopted_maps/${analysis.config?.adopted_map[0]}`,
          analysis.name
        );
        // translate legend items here, where we have react context
        const translatedLegend = adoptedMapLayer.legendConfig.items.map((item) => ({
          value: item.value,
          name: t(item.name),
          color: item.color,
        }));
        adoptedMapLayer.legendConfig.items = translatedLegend;
        setMapLayer(adoptedMapLayer);
        toggleLayer(adoptedMapLayer);
      }
    }
  };

  const fetchLocation = async (slug) => {
    try {
      const { data } = await PlacesService.fetchPlaceById(slug, {
        group: privateGroups.join(','), // do not filter here, prod shapes are owned by UNBL
        select: 'bbox2d,geojson',
      });

      if (data.bbox2d.length) {
        setMapBounds({ bbox: data.bbox2d });
      } else {
        resetMap();
      }

      if (data.geojson) {
        setLocationHighlight({
          id: data.id,
          geojson: data.geojson,
        });
      }
    } catch (e) {
      console.error(e);
      setServerErrors([{ detail: t('ELSA unable to get location') }]);
      resetMap();
    }
  };

  const onSubmit = () => {
    if (!formComplete) {
      return false;
    }
    const org = watch('organization');
    if (org && analysis) {
      // don't actually submit the form, but go on to the next state
      push(`/elsa/${org}/${analysis.slug}`);
    } else {
      setServerErrors([
        {
          detail: t('ELSA unable to get analysis {{analysis}} for org {{org}}', {
            analysis,
            org,
          }),
        },
      ]);
    }
  };

  return (
    <div className="marapp-qa-elsa-new">
      <Box mb={1}>
        <Paper elevation={3} square={true} className={classes.header}>
          <Box p={2}>
            <Typography variant="h5" component="h2" color="textPrimary">
              {t('Create a new ELSA Map')}
            </Typography>
          </Box>
        </Paper>
      </Box>

      <Paper square={true}>
        <Box p={2} mb={1}>
          <Grid container={true} direction="column" spacing={3}>
            <Grid item={true}>
              <Typography variant="subtitle1" gutterBottom={true}>
                {t('What is an ELSA Map?')}
              </Typography>

              <Typography variant="body1">
                {analysisAvailable
                  ? t(
                      'Maps of Essential Life Support Areas (ELSAs) identify where nature-based solutions for protection, management, restoration, and urban greening can best help to achieve national priorities for nature, climate, and sustainable development. The ELSA tool on UNBL enables countries to create a national terrestrial map of priority areas where nature-based actions can support globally agreed-upon targets.'
                    )
                  : t('You currently do not have any ELSA maps available')}
                <br />
                <span
                  className={classes.link}
                  dangerouslySetInnerHTML={{
                    __html: t('Visit our Maps of Hope page to learn more.', {
                      link: 'https://unbiodiversitylab.org/maps-of-hope/',
                    }),
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Paper>
        <Box p={2} mt={1}>
          {hasMultiplePrivateGroups ? (
            <Grid item={true}>
              <Typography variant="subtitle2" color="textSecondary">
                {t('Select a Workspace')}
              </Typography>
              <Controller
                name="organization"
                control={control}
                rules={{ required: true }}
                defaultValue={defaultPrivateGroup}
                as={
                  <RadioGroup className="marapp-qa-collection-organizations">
                    {privateGroupsFiltered.map((group) => (
                      <FormControlLabel
                        key={group}
                        value={group}
                        control={<Radio onChange={(e) => onChangeGroup(e.target.value)} />}
                        label={group}
                      />
                    ))}
                  </RadioGroup>
                }
              />
            </Grid>
          ) : (
            <Controller
              name="organization"
              className={classes.hidden}
              control={control}
              value={defaultPrivateGroup}
              as={<TextField type="hidden" />}
            />
          )}

          {selectedGroup ? (
            <Grid item={true}>
              <Box mt={1}>
                {analysisAvailable ? (
                  <>
                    <Typography variant="subtitle2" color="textSecondary">
                      {t('Select a place for your ELSA Map')}
                    </Typography>
                    <Controller
                      name="analysis"
                      control={control}
                      defaultValue={''}
                      rules={{ required: true }}
                      as={
                        <RadioGroup className="marapp-qa-elsa-analysis">
                          {analysisInWorkspace?.length
                            ? analysisInWorkspace.map((analysis) => (
                                <FormControlLabel
                                  key={analysis.slug}
                                  value={analysis.id}
                                  control={
                                    <Radio onChange={(e) => onChangeAnalysis(e.target.value)} />
                                  }
                                  label={analysis.name}
                                />
                              ))
                            : t('No Analyses available to run in the selected workspace')}
                        </RadioGroup>
                      }
                    />
                  </>
                ) : (
                  <Typography variant="subtitle2" color="textSecondary">
                    {t('No Analyses available to run in the selected workspace')}
                  </Typography>
                )}
              </Box>
            </Grid>
          ) : (
            <></>
          )}
        </Box>
      </Paper>

      <Box p={2}>
        <Grid container={true} spacing={1}>
          <Grid item={true}>
            <Button
              className="marapp-qa-modify-elsa"
              variant="contained"
              color="primary"
              type="submit"
              size="small"
              disabled={!formComplete}
              onClick={onSubmit}
            >
              {t('Modify ELSA Map')}
            </Button>
          </Grid>
          <Grid item={true}>
            <Button
              component={Link}
              className="marapp-qa-cancel-elsa"
              size="small"
              variant="outlined"
              to={{
                type: EarthRoutes.EARTH,
                query: prev.query,
              }}
            >
              {t('Cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <ErrorMessages errors={serverErrors} onClose={() => setServerErrors(null)} />
    </div>
  );
}

export default connect(
  (state: any) => ({
    ...state.router.payload,
  }),
  {
    resetMap,
    setLocationHighlight,
    setMapBounds,
    setLayersActive,
    resetLayersActive,
    toggleLayer,
  }
)(WithData);
