/*
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *  https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconInfo from 'mdi-material-ui/InformationOutline';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Link from 'redux-first-router-link';

import { checkAdminRole, Input, QUERIES, setupErrors } from '@marapp/earth-shared';

import { MAP_SIDEBAR_WIDTH_WIDE } from '../../config';
import { useAnalysis } from '../../fetchers';
import { EarthRoutes, IRouter } from '../../modules/router/model';
import { Auth0Context } from '../../utils/contexts';

interface IProps {
  router?: IRouter;
  container: HTMLElement;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTypography-subtitle1': {
      fontWeight: 400,
    },
  },
  link: {
    '& a': {
      textDecoration: 'underline',
    },
  },
  table: {
    '& .MuiTableCell-stickyHeader': {
      top: -10,
      backgroundColor: theme.palette.background.paper,
      border: 'none',
    },
  },
  weight: {
    textAlign: 'right',
    width: 60, // wide enough to fit 4 digits
  },
  tooltip: {
    background: '#616161',
    fontSize: 12,
    maxWidth: 500,
    '& ul': {
      paddingLeft: '1em',
    },
  },
}));

function WithData(props: IProps) {
  const classes = useStyles();
  const { router, container } = props;
  const { prev } = router;
  const { slug, organization } = router.payload;
  const { roles } = useContext(Auth0Context);
  const { data: analysis, error } = useAnalysis(
    slug,
    QUERIES.ANALYSIS.getAllFiltered(organization)
  );

  const isAdmin = organization ? checkAdminRole(roles[organization]) : false;
  const { control, errors, formState, watch } = useFormContext();
  const { touched, isValid, isSubmitting } = formState;
  const [formErrors, setFormErrors] = useState({});
  // use local state to track formErrors
  // seems more responsive than getting it from formContext
  useEffect(() => {
    setFormErrors(formState.errors);
  }, [formState]);
  const [showSave, setShowSave] = useState(false);

  // watch and save to a local variable, so we get immediate onChange feedback when a name is entered
  const analysisName = watch('name');
  const allowSubmit = Object.keys(formErrors).length === 0 && analysisName.length > 0;

  const renderErrorFor = setupErrors(formErrors, touched);
  const { t } = useTranslation('unbl');

  return (
    <>
      <Dialog
        container={container}
        disablePortal={true}
        disableEnforceFocus={true}
        disableAutoFocus={true}
        scroll={'paper'}
        open={!showSave}
        fullWidth={true}
        maxWidth={'lg'}
        className={classes.root}
        style={{
          // have to include these inline to override the dialog defaults
          position: 'absolute',
          zIndex: 1000,
          left: MAP_SIDEBAR_WIDTH_WIDE,
          padding: '8em 0',
        }}
      >
        <DialogTitle disableTypography={true}>
          <Typography variant="subtitle1">
            {t('ELSA Weights')}
            &nbsp;
            <Tooltip
              interactive={true}
              classes={{ tooltip: classes.tooltip }}
              title={
                <Box p={1}>
                  {t(
                    'Each dataset in this table is used as input to the ELSA analysis. Each dataset was selected on a per-country basis to serve as a proxy for the ten global priority targets. The ELSA analysis seeks to create a single map of spatially optimized outcomes using all input datasets.'
                  )}
                  {t(
                    'Rank the comparative importance of each input dataset by entering a weight between 0 and 10 to influence the result of your custom ELSA analysis:'
                  )}
                  <ul>
                    <li>0 &mdash; {t('not important / do not consider')}</li>
                    <li>2.0 &mdash; {t('low importance')}</li>
                    <li>5.0 &mdash; {t('average importance')}</li>
                    <li>10.0 &mdash; {t('highest importance')}</li>
                  </ul>
                  <span
                    className={classes.link}
                    dangerouslySetInnerHTML={{
                      __html: t('View the rapid ELSA analysis user guide to learn more.', {
                        link: 'https://unbiodiversitylab.org/rapid-elsa-analysis-user-guide/',
                      }),
                    }}
                  />
                  {t('')}
                </Box>
              }
              placement="bottom-start"
            >
              <IconInfo fontSize="small" />
            </Tooltip>
          </Typography>
          <Typography variant="body1">
            {t(
              'Enter a weight between 0 (not considered) and 10 (highest importance) for each data layer based on its relative importance for the country.'
            )}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TableContainer style={{ overflowX: 'initial' }}>
            <Table
              className={`${classes.table} marapp-qa-elsa-weights`}
              size="small"
              stickyHeader={true}
            >
              <TableHead>
                <TableRow>
                  <TableCell />

                  <TableCell>
                    <Typography variant="subtitle1">{t('Data')}</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant="subtitle1">
                      {t('Global Policy Target')}
                      &nbsp;
                      <Tooltip
                        interactive={true}
                        classes={{ tooltip: classes.tooltip }}
                        title={
                          <Box p={1}>
                            {t(
                              'Ten global policy targets guide the rapid ELSA analysis. A rigorous peer-reviewed process ensured all targets were representative of pilot ELSA countries, comprehensive, have widespread global support, and are mappable.'
                            )}
                            &nbsp;
                            <span
                              className={classes.link}
                              dangerouslySetInnerHTML={{
                                __html: t(
                                  'View the targets and access the global policy analysis to learn more.',
                                  {
                                    link1:
                                      'https://unbiodiversitylab.org/elsa-10-priority-global-targets/',
                                    link2:
                                      'https://unbiodiversitylab.org/elsa-global-policy-analysis/',
                                  }
                                ),
                              }}
                            />
                            {t('')}
                          </Box>
                        }
                        placement="bottom-start"
                      >
                        <IconInfo fontSize="small" />
                      </Tooltip>
                    </Typography>
                  </TableCell>

                  <TableCell width={60}>
                    <Typography variant="subtitle1">{t('Weight')}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>{/* dummy row to get colors to start with contrast */}</TableRow>
                {analysis?.config?.layers?.map((w, index) => {
                  // set defaults
                  const MIN = w.min || 0;
                  const STEP = w.step || 0.1;
                  const NAME = w.name.toLowerCase().replaceAll(' ', '_');

                  return (
                    <TableRow key={`weight-${index}`}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{w.name}</TableCell>
                      <TableCell>{w.policy_num || ''}</TableCell>
                      <TableCell>
                        <Controller
                          className={classes.weight}
                          control={control}
                          name={`weights.${NAME}`}
                          defaultValue={w.weight}
                          as={Input}
                          variant="standard"
                          size="small"
                          fullWidth={true}
                          type="number"
                          min={MIN}
                          step={STEP}
                          error={renderErrorFor(`weights.${NAME}`)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Box py={1} mr={3} className="MuiDialogActions-spacing">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setShowSave(true);
              }}
            >
              {t('Create ELSA Map')}
            </Button>
            <Button
              variant="outlined"
              component={Link}
              to={{
                type: EarthRoutes.ELSA_NEW,
                query: prev.query,
              }}
            >
              {t('Cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showSave}
        style={{
          // have to include these inline to override the dialog defaults
          position: 'absolute',
          zIndex: 1000,
          left: MAP_SIDEBAR_WIDTH_WIDE,
        }}
      >
        <DialogTitle disableTypography={true}>
          <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
            {t('Finalize ELSA Map')}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Controller
            className="marapp-qa-elsa-name"
            control={control}
            label={t('Map name')}
            placeholder={t('enter a name for your map')}
            error={renderErrorFor('name')}
            name="name"
            as={Input}
            required={true}
            rules={{ required: true }}
          />

          {isAdmin && (
            <>
              <Box py={2}>
                <Controller
                  className="marapp-qa-elsa-blm"
                  control={control}
                  as={Input}
                  label={t('Input boundary penalty factor')}
                  placeholder={'0'}
                  error={renderErrorFor('blm')}
                  name="blm"
                />
                <Box px={2}>
                  <FormHelperText>
                    {t(
                      'Increasing the BPF will produce greater degrees of clumping in output solutions, resulting in larger contiguous areas for each ELSA action.'
                    )}
                  </FormHelperText>
                </Box>
              </Box>

              <Typography variant="body2">
                {t(
                  'Optimize map separately for biodiversity, climate change, human well-being, and all?'
                )}
              </Typography>
              <Box px={2}>
                <FormControlLabel
                  control={
                    <Controller
                      className="marapp-qa-elsa-multipri"
                      control={control}
                      defaultValue={false}
                      error={renderErrorFor('multipri')}
                      label={t('yes')}
                      name="multipri"
                      render={({ name, value, onChange }) => (
                        <Checkbox
                          name={name}
                          checked={!!value}
                          value={true}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label={t('Yes')}
                />
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            form="elsa-form"
            disabled={isSubmitting || !allowSubmit}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : <></>}
          >
            {t('Create ELSA Map')}
          </Button>
          <Button variant="outlined" onClick={() => setShowSave(false)}>
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default connect(
  (state: any) => ({
    ...state.router.payload,
  }),
  {}
)(WithData);
