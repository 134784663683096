import Collapse from '@material-ui/core/Collapse';
import React from 'react';

import { Chart } from '@marapp/earth-shared';

import WidgetNoDataComponent from '../../../../components/widget/no-data';
import WidgetTemplateText from '../../templateText';
import { IWidgetTemplate } from '../model';

const EVI = (props: IWidgetTemplate) => {
  const { chart, config, expanded, noData, template } = props;

  if (noData) {
    return <WidgetNoDataComponent expanded={expanded} />;
  }

  return (
    <>
      <WidgetTemplateText expanded={expanded} template={template} />

      {/* Chart */}
      {!!chart.length && (
        <Collapse in={!!expanded}>
          <Chart data={chart} config={config} />
        </Collapse>
      )}
    </>
  );
};

export default EVI;
