import { BaseAPIService, responseNonDeserializer } from './base/APIBase';
type clickEntityType = 'layer' | 'location';

/**
 * Session based click count recording service
 */
class AnalyticService {
  /**
   * Singleton pattern is used to access this class just to avoid
   * multiple instances getting created as it should record a single click of an entity per single session
   */
  public static getInstance(): AnalyticService {
    if (!AnalyticService.instance) {
      AnalyticService.instance = new AnalyticService();
    }

    return AnalyticService.instance;
  }

  private static instance: AnalyticService;
  private clickedEntities: Record<clickEntityType, string[]> = {
    location: [],
    layer: [],
  };

  private constructor() {
    if (typeof window !== 'undefined') {
      const storedData = sessionStorage.getItem('clickedEntities');
      if (storedData) {
        this.clickedEntities = JSON.parse(storedData);
      }
    }
  }

  public addClickCount(clickEntity: clickEntityType, clickEntityId: string) {
    if (!this.clickedEntities[clickEntity]) {
      console.error(`Click entity ${clickEntity} not supported`);
      return;
    }

    if (this.clickedEntities[clickEntity].includes(clickEntityId)) {
      return;
    }

    this.recordClick(clickEntity, clickEntityId);
    this.clickedEntities[clickEntity].push(clickEntityId);

    if (typeof window !== 'undefined') {
      sessionStorage.setItem('clickedEntities', JSON.stringify(this.clickedEntities));
    }
  }

  private async recordClick(clickEntity: clickEntityType, clickEntityId: string) {
    /**
     * Encircled record click count logic within a try catch block to avoid affecting
     * other parts of the page components in case of a failure in recording the click count
     */
    try {
      return await BaseAPIService.request(
        `/analytics`,
        {
          method: 'post',
          data: {
            entityId: clickEntityId,
            entityType: clickEntity,
          },
        },
        responseNonDeserializer
      );
    } catch (e) {
      return () => console.error('Failed to record click count', e);
    }
  }
}

export default AnalyticService.getInstance() as AnalyticService;
