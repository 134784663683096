import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';

interface IMapControlListItemProps {
  avatar?: any;
  label: any;
}

const MapControlListItem = (props: IMapControlListItemProps) => {
  const { avatar, label } = props;

  return (
    <>
      {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
      <ListItemText primary={label} />
    </>
  );
};

export default MapControlListItem;
