/*
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *  https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MuiSelect, TitleHero } from '@marapp/earth-shared';

import { ICollection } from '../../../modules/places/model';

interface IDownloadMetricData {
  metrics: string[];
  fileType: string;
}

interface IProps {
  collection: ICollection;
  isLoadingMetricSlugs: boolean;
  metricSlugs: any[];
  onCancel: () => void;
  onDownloadMetricData: (values: IDownloadMetricData) => void;
}

const FILE_TYPES = [
  { name: 'CSV', value: 'csv', className: 'marapp-qa-downloadmetricscsv' },
  { name: 'JSON', value: 'json', className: 'marapp-qa-downloadmetricsjson' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
  },
  header: {
    backgroundColor: theme.palette.grey['600'],
  },
  scrollContainer: {
    flex: '1 1 auto',
    overflow: 'auto',
  },
}));

export function CollectionDownloadMetrics(props: IProps) {
  const { isLoadingMetricSlugs, metricSlugs, collection, onCancel, onDownloadMetricData } = props;
  const { t } = useTranslation();
  const { name, organization } = collection;
  const { handleSubmit, formState, control, watch } = useForm({
    mode: 'all',
    defaultValues: {
      fileType: '',
    },
  });
  const { isDirty, isValid, isSubmitting } = formState;
  const metricsWatcher = watch('metrics');
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit(onDownloadMetricData)} className={classes.root}>
      <Box mb={1}>
        <Paper square={true} elevation={3} className={classes.header}>
          <Box p={2}>
            <TitleHero title={name} subtitle={organization} extra={t('Collection')} />
          </Box>
        </Paper>
      </Box>

      <div className={classes.scrollContainer}>
        <Paper square={true}>
          <Box p={2}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Controller
                  as={MuiSelect}
                  name="metrics"
                  label={t('Select metrics for download')}
                  placeholder={t('Select metrics to download data files')}
                  className="marapp-qa-downloadmetricsdropdown"
                  options={metricSlugs.map((metric) => metric.value)}
                  isLoading={isLoadingMetricSlugs}
                  defaultValue={[]}
                  control={control}
                  multiple={true}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <Typography component="label">{t('Select a file type for download')}</Typography>

                <Controller
                  name="fileType"
                  control={control}
                  as={
                    <RadioGroup row={true}>
                      {FILE_TYPES.map((type) => (
                        <FormControlLabel
                          control={<Radio />}
                          className={type.className}
                          key={type.name}
                          label={type.name}
                          value={type.value}
                        />
                      ))}
                    </RadioGroup>
                  }
                  rules={{
                    required: true,
                  }}
                />
              </Grid>

              <Grid item={true} xs={12} container={true} spacing={1}>
                <Grid item={true}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    size="large"
                    className="marapp-qa-actiondownload"
                    disabled={!isValid || isSubmitting || !isDirty || !metricsWatcher?.length}
                    endIcon={isSubmitting && <CircularProgress size={16} />}
                  >
                    {t(isSubmitting ? 'Downloading' : 'Download')}
                  </Button>
                </Grid>
                <Grid item={true}>
                  <Button className="marapp-qa-actioncancel" onClick={onCancel} size="large">
                    {t('Cancel')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </div>
    </form>
  );
}
