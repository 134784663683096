import { ILayer, LayerType } from '../../modules/layers/model';
import { MAP_TITILER_URL } from '../../config';
import { ELSAOutput } from '../../modules/analysis/model';

const ELSA_CATEGORICAL_LEGEND = {
  type: 'basic',
  items: [
    {
      value: 0,
      name: '',
    },
    {
      value: 1,
      name: 'Protect',
      color: '#3D528B',
    },
    {
      value: 2,
      name: 'Manage',
      color: '#22908D',
    },
    {
      value: 3,
      name: 'Restore',
      color: '#5CC863',
    },
    {
      value: 4,
      name: 'Urban Greening',
      color: '#FDE726',
    },
  ],
};

const ELSA_CONTINUOUS_LEGEND = {
  type: 'gradient',
  items: [
    {
      value: 0,
      name: 'Min',
      color: '#3F0952',
    },
    {
      value: 0.25,
      name: 'Low',
      color: '#3D528B',
    },
    {
      value: 0.5,
      name: 'Medium',
      color: '#22908D',
    },
    {
      value: 0.75,
      name: 'High',
      color: '#5CC863',
    },
    {
      value: 1,
      name: 'Max',
      color: '#FDE726',
    },
  ],
};

const splitPath = (path: string): { filename: string; directory: string } => {
  const [last, ...rest] = path.split('/').reverse();
  const directory = rest.reverse().join('/');
  const filename = last.replace('.tif', '');
  return { filename, directory };
};

export const PreviewELSALayer = (path: string, name: string = '', slug: string = ''): ILayer => {
  // creates a temporary layer object from a tif in blob storage
  const { filename, directory } = splitPath(path);
  const [_, ...rest] = path.replace('https://', '').split('/');
  const [blobPath, ...sas] = rest.join('/').split('?');

  if (!slug) {
    slug = filename.replaceAll('_', '-');
  }
  let legend = {};
  let rescale = '';
  const colormap = 'viridis';
  if (!name) {
    name = filename.replaceAll('_', ' ');
  }

  if (directory === '/elsa-inputs/elsa_inputs' || filename.indexOf('_HM') > 0) {
    // all inputs are continuous
    legend = ELSA_CONTINUOUS_LEGEND;
    rescale = '0,1';
  } else {
    // adopted and generated maps are categorical
    legend = ELSA_CATEGORICAL_LEGEND;
    rescale = '0,4';
  }

  const layer = {
    id: path,
    slug,
    name,
    category: ['ELSA'],
    licenseRestriction: 'none',
    createdAt: '',
    updatedAt: '',
    version: 0,
    description: '',
    interactionConfig: {},
    legendConfig: legend,
    layerConfig: {},
    published: true,
    source: {
      type: 'raster',
      tiles: [
        `${MAP_TITILER_URL}/cog/tiles/{z}/{x}/{y}@2x.png?url=/vsiaz/${blobPath}&rescale=${rescale}&colormap_name=${colormap}`,
      ],
    },
    type: LayerType.raster,
  };
  return layer;
};

export const PreviewELSAGroup = (
  path: string,
  name: string = '',
  slug: string = '',
  layerList: ELSAOutput[]
): ILayer => {
  // creates a temporary layer group with internal references to the layerList
  const GROUP_LEGEND = { legendType: 'yearpicker' };
  // use yearpicker to get dropdown of referenced layers

  const group = {
    id: path,
    slug,
    name,
    legendConfig: GROUP_LEGEND,
    source: {
      format: 'image/png',
      type: LayerType.raster,
    },
    type: LayerType.group,
    references: layerList.map((l) =>
      PreviewELSALayer(l.blob_url || `${path}/${l.file_path}`, l.name)
    ),
  };

  return group;
};

// const IO_STORAGE_URL = 'https://impactobservatory.blob.core.windows.net';
// const ELSA_CONTAINER = 'io-elsa';
// const ELSA_READ_SAS =
//   'sv=2021-06-08&ss=bfqt&srt=o&sp=rtf&se=2024-02-04T07:30:30Z&st=2023-02-03T23:30:30Z&spr=https&sig=yfivyZkfKonzPMqRH1oJfpmpfRy2k09%2B83hHlthTcJc%3D';

// export const getExternalURL = (filename: string, directory: string) =>
//   `${IO_STORAGE_URL}/${ELSA_CONTAINER}/${directory}/${filename}?${ELSA_READ_SAS}`;
