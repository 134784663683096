import Box from '@material-ui/core/Box';
import MuiList from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import List from '@researchgate/react-intersection-list';
import React from 'react';

import { MenuItemSkeleton, NoSearchResultsText } from '@marapp/earth-shared';

import { PAGE_SIZE } from '../../theme';

interface IProps {
  data: any[];
  search?: string;
  isValidating: boolean;
  awaitMore: boolean;
  children: (item: any) => React.ReactElement;
  onNextPage: (config: any) => void;
  pageSize?: number;
  title?: string;
  totalResults?: number;
}

const InfiniteList = (props: IProps) => {
  const {
    data,
    search,
    isValidating,
    awaitMore,
    pageSize = PAGE_SIZE,
    children,
    onNextPage,
    title,
    totalResults,
  } = props;

  return (
    <div className="marapp-qa-infinitelist">
      {title && (
        <Box p={2} pb={0}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" color="textSecondary">
              {title}&nbsp;
              {!isValidating && <em>({totalResults})</em>}
            </Typography>
          </Box>
        </Box>
      )}
      {data?.length ? (
        <List
          awaitMore={awaitMore}
          pageSize={pageSize}
          itemCount={data.length}
          renderItem={(index) => children(data[index])}
          itemsRenderer={(items, ref) => <MuiList ref={ref}>{items}</MuiList>}
          onIntersection={onNextPage}
        />
      ) : isValidating ? null : (
        <NoSearchResultsText search={search} />
      )}
      {isValidating && <MenuItemSkeleton />}
    </div>
  );
};

export default InfiniteList;
