/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import { format } from 'd3-format';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import React from 'react';

import WidgetTooltip from '../../../../components/widget/tooltip';
import { IPlace } from '../../../../modules/places/model';
import { IWidgetConfig } from '../../../../modules/widget/model';
import { replace } from '../../utils';
import { LandCoverMetric } from './model';

interface LandCoverConfig {
  metric: LandCoverMetric;
}

export const CONFIG = {
  parse: ({ metric }: LandCoverConfig, params, widgetConfig: IWidgetConfig, place: IPlace) => {
    if (!metric?.data) {
      return {
        noData: true,
        chart: [],
        template: '',
      };
    }

    const { sentence, legendConfig } = widgetConfig;
    const { data, year } = metric;

    const px_year = sum(
      Object.values(data).map((value) => {
        return value;
      })
    );

    let dataPerc = {};
    Object.keys(data).forEach((key) => {
      dataPerc = {
        ...dataPerc,
        [key]: (100 * data[key]) / px_year,
      };
    });

    const graphValues = Object.keys(dataPerc).reduce((acc, value) => {
      return {
        ...acc,
        [value.toLowerCase()]: dataPerc[value],
      };
    }, {});

    return {
      chart: sortBy(
        legendConfig.items
          .filter((l) => graphValues[l.label.toLowerCase().replaceAll(' ', '_')])
          .map((l) => {
            const percentage = graphValues[l.label.toLowerCase().replaceAll(' ', '_')];
            const area = data[l.label.toLowerCase().replaceAll(' ', '_')];
            return {
              name: l.label,
              color: l.color,
              percentage,
              area,
              y: 100,
            };
          }),
        'percentage'
      ),
      template: replace(
        sentence.default,
        {
          location: place.name,
          year,
        },
        {},
        {
          className: 'text-bold',
        }
      ),
      config: {
        type: 'pie',
        margin: { top: 0, right: 0, left: 0, bottom: 0 },
        xKey: 'percentage',
        yKeys: {
          pies: {
            y: {
              cx: '60%',
              cy: '45%',
              dataKey: 'percentage',
              nameKey: 'name',
              innerRadius: '60%',
              outerRadius: '80%',
            },
          },
        },
        legend: {
          align: 'left',
          verticalAlign: 'middle',
          layout: 'vertical',
          content: (properties) => {
            /**
             * Our implementation of the pie chart filters out all the 0 values from being displayed
             * on the chart.
             *
             * In order to display them in the legend, we need to gather the legend info from
             * the 'data' prop, instead of 'payload' which is received from the pie chart implementation
             * itself.
             */
            const { data } = properties;
            const groups = groupBy(data, (d) => d.category);

            return (
              <div className="widget--legend">
                {Object.keys(groups).map((g) => (
                  <div key={g} className="widget--legend-group">
                    <ul className="widget--legend-list">
                      {sortBy(groups[g], 'payload.percent')
                        .reverse()
                        .map((item) => (
                          <li key={`item-${item.color}`} className="widget--legend-list-item">
                            <span
                              className="widget--legend-list-item-square"
                              style={{ background: item.color }}
                            />

                            <span>
                              {item.name}{' '}
                              <span className="widget--legend-list-item-value">
                                {' '}
                                -{' '}
                                {(item.percentage < 0.005 ? format('.3%') : format('.2%'))(
                                  item.percentage / 100
                                )}
                              </span>
                            </span>
                          </li>
                        ))}
                    </ul>
                  </div>
                ))}
              </div>
            );
          },
        },
        tooltip: {
          cursor: false,
          content: (
            <WidgetTooltip
              settings={[
                {
                  label: 'Category:',
                  key: 'name',
                  format: (value: string) => {
                    if (value === 'Permanent Snow and Ice') {
                      return 'Snow and Ice';
                    }
                    return value;
                  },
                },
                {
                  label: 'Area:',
                  key: 'area',
                  format: (value) => `${format(',.0f')(value)} km²`,
                },
                {
                  label: 'Percentage:',
                  key: 'percentage',
                  format: (value) => {
                    return value < 0.01 ? '< 0.01%' : `${format('.2%')(value / 100)}`;
                  },
                },
              ]}
            />
          ),
        },
      },
    };
  },
};

export default CONFIG;
