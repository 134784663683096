/*
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *  https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import IconAlert from 'mdi-material-ui/Alert';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IMetricTableData } from './config';

interface IProps {
  metricData: any;
  metricSlug: string;
}

enum ColumnAlignment {
  left = 'left',
  center = 'center',
  right = 'right',
}

interface CollectionMetricColumn {
  key: string;
  dataKey: string;
  title?: string;
  align?: ColumnAlignment;
  className?: any;
  sortable?: boolean;
  [key: string]: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& tr:nth-of-type(odd), th': {
      background: theme.palette.background.paper,
    },
    '& tr:nth-of-type(even)': {
      background: theme.palette.grey['600'],
      '& th': {
        background: theme.palette.grey['600'],
      },
    },
    '& thead $sticky': {
      zIndex: 10,
    },
    '& thead *': {
      background: `${theme.palette.background.paper} !important`,
    },
    '& th': {
      ...theme.typography.body1,
      fontWeight: 500,
    },
    '& th, td': {
      minWidth: 120,
    },
  },
  nestedHeader: {
    verticalAlign: 'bottom',
    '& *': {
      borderBottom: 'none',
      verticalAlign: 'baseline',
    },
    '& th': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  sticky: {
    position: 'sticky',
    left: 0,
    maxWidth: '40vh',
    background: 'inherit',
    [theme.breakpoints.up('sm')]: {
      minWidth: '250px !important',
    },
  },
  verticalDivider: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  container: {
    maxHeight: 'calc(100vh - 296px)',
  },
}));

export function CollectionMetricsTable(props: IProps) {
  const { metricData } = props;

  const classes = useStyles();
  const [columns, setColumns] = useState([]);
  const [hasNestedColumns, setHasNestedColumns] = useState(false);
  const [sortKey, setSortKey] = useState('location');
  const [sortDirection, setSortDirection] = useState('asc');
  const { t } = useTranslation();
  const validMetricEntry = metricData?.find((entry) => entry?.location);

  // @ts-ignore
  const sortedData =
    metricData &&
    orderBy(metricData, [`${sortKey}.value`, sortKey], [sortDirection, sortDirection]);

  const onChangeSorting = (newSortingKey: string) => {
    if (newSortingKey === sortKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortDirection('asc');
      setSortKey(newSortingKey);
    }
  };

  const getColumnDetails = (metricData: object, parentKey: string) => {
    return (
      metricData &&
      Object.keys(metricData)
        .filter((key) => key !== 'id')
        .reduce((acc, key) => {
          const dataKey = `${parentKey ? `${parentKey}.children.` : ''}${key}`;
          const children = metricData[key].children;
          const isLocation = key === 'location';
          const column: CollectionMetricColumn = {
            key: dataKey,
            dataKey,
            title: key,
            align: ColumnAlignment.right,
            sortable: !children,
            ...(isLocation && {
              align: ColumnAlignment.left,
              className: classes.sticky,
            }),
            ...(children && {
              children: getColumnDetails(children, dataKey),
            }),
          };
          const newAcc = [...acc];
          isLocation ? newAcc.unshift(column) : newAcc.push(column);

          return newAcc;
        }, [])
    );
  };

  const getTableColumns = useCallback((): CollectionMetricColumn[] => {
    let tableColumns: CollectionMetricColumn[] = [];

    if (validMetricEntry) {
      tableColumns = getColumnDetails(validMetricEntry, '');
    }

    return tableColumns;
  }, [validMetricEntry]);

  const renderHeaderColumn = (column, depth = 0) => {
    const { dataKey, children, title, sortable, ...rest } = column;
    const rootCellProps = {
      ...(hasNestedColumns && {
        classes: {
          root: classNames(classes.nestedHeader, {
            [`${classes.verticalDivider}`]: !depth,
          }),
        },
      }),
      ...(children && {
        colSpan: children?.length,
        style: { paddingLeft: 0, paddingRight: 0, paddingBottom: 0, paddingTop: 0 },
      }),
    };

    return (
      <TableCell {...rest} {...rootCellProps}>
        {children ? (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align={ColumnAlignment.right}
                    className={`marapp-qa-table-head marapp-qa-table-head-${dataKey}`}
                    colSpan={children?.length}
                  >
                    {t(title)}
                  </TableCell>
                </TableRow>

                <TableRow>{children.map((child) => renderHeaderColumn(child, depth + 1))}</TableRow>
              </TableHead>
            </Table>
          </>
        ) : (
          <TableSortLabel
            active={sortKey === dataKey}
            className={`marapp-qa-table-head marapp-qa-table-head-${dataKey}`}
            direction={sortDirection}
            onClick={() => onChangeSorting(dataKey)}
            disabled={!sortable}
          >
            {t(title)}
          </TableSortLabel>
        )}
      </TableCell>
    );
  };

  const renderBodyColumn = (column, row, depth = 0) => {
    const { rowSpan, colSpan, children, dataKey, title, sortable, ...rest } = column;

    return children
      ? children.map((child, index) => {
          return renderBodyColumn(child, row, index === children.length - 1 ? 0 : depth + 1);
        })
      : renderMetricValue(get(row, dataKey), { ...rest, dataKey }, depth);
  };

  const renderMetricValue = (value, props, depth = 0) => {
    const { className, dataKey, ...rest } = props;
    const classesList = classNames(
      'marapp-qa-table-body-cell',
      `marapp-qa-table-body-cell-${dataKey}`,
      {
        [className]: !!className,
        [`${classes.verticalDivider}`]: !depth && hasNestedColumns,
      }
    );

    return (
      <TableCell className={classesList} {...rest}>
        <Typography variant="body2">
          {value?.displayValue || value?.value || value || '-'}
        </Typography>
      </TableCell>
    );
  };

  useEffect(() => {
    let columns: CollectionMetricColumn[] = [];

    if (validMetricEntry) {
      columns = getTableColumns();
    }

    const hasNestedColumns = columns.find((column) => column.children);

    setColumns(columns);
    setHasNestedColumns(!!hasNestedColumns);
  }, [metricData, validMetricEntry]);

  if (!metricData) {
    return null;
  }

  return (
    <>
      {validMetricEntry ? (
        <TableContainer className={classes.container}>
          <Table
            className={classNames(classes.root, 'marapp-qa-collections-metric-table')}
            stickyHeader={true}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return renderHeaderColumn(column);
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row: IMetricTableData) => (
                <TableRow key={row.id}>
                  {columns.map((column) => renderBodyColumn(column, row))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box p={2} pt={0}>
          <Typography>
            <IconAlert />
            {t('Cannot generate collection metric table')}
          </Typography>
        </Box>
      )}
    </>
  );
}
