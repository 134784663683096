import { format } from 'd3-format';
import React from 'react';

import { LegendConfig } from '../../modules/widget/model';

export const formatKM2 = (x: number): string => {
  if (x >= 100) {
    return format(',.0f')(x);
  }
  if (x >= 10) {
    return format('.1f')(x);
  }
  if (x >= 0.01) {
    return format('.2f')(x);
  }
  if (x === 0) {
    return '0';
  }
  return '< 0.01';
};

export const formatPercentage = (v) => {
  switch (true) {
    case v < 0.01: {
      return ',.3~%';
    }

    default: {
      return ',.2~%';
    }
  }
};

export const stringOrHtml = (originalStr, props) => {
  let str = originalStr;
  const { className } = props;

  if (className) {
    str = `<span class="${className}">${str}</span>`;
  }

  return str;
};

/**
 * Params should have this format => { key:'xxx', key2:'xxx' }
 * Keys to search should be in this format {{key}}
 * @param {String} originalStr
 * @param {Object} params
 */
export const substitution = (originalStr, params = {}, options = {}) => {
  let str = originalStr;
  Object.keys(params).forEach((key) => {
    const p = stringOrHtml(params[key], options);

    str = str.replace(new RegExp(`{{${key}}}`, 'g'), p).replace(new RegExp(`{${key}}`, 'g'), p);
  });
  return str;
};

/**
 * Replace function
 * @param {String} string
 * @param {Object} params
 * @param {Object} sqlParams
 */
export const replace = (originalStr, params = {}, sqlParams = {}, options = {}) => {
  let str = originalStr;

  if (typeof str === 'string') {
    str = substitution(str, params, options);
  }

  return str;
};

export const hexToRGB = (hex: string): number[] => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return [r, g, b];
};

export const findColourAlongGragient = (startColor: string, endColor: string, fraction: number) => {
  const startColorRGB = hexToRGB(startColor);
  const endColorRGB = hexToRGB(endColor);
  const r = Math.round(startColorRGB[0] + fraction * (endColorRGB[0] - startColorRGB[0]));
  const g = Math.round(startColorRGB[1] + fraction * (endColorRGB[1] - startColorRGB[1]));
  const b = Math.round(startColorRGB[2] + fraction * (endColorRGB[2] - startColorRGB[2]));
  return `rgb(${r}, ${g}, ${b})`;
};

export const findColoursAlongGradient = (
  markersAlongGradient: number[],
  maxValueOnGradient: number,
  colourGradient: { [value: number]: string }
): string[] => {
  const colourBoundaries = Object.keys(colourGradient)
    .map((k) => Number(k))
    .sort((a, b) => a - b);
  const colours = [];
  for (let i = 0; i <= markersAlongGradient.length - 1; i++) {
    const marker = markersAlongGradient[i];
    const nextMarker = markersAlongGradient[i + 1] || maxValueOnGradient;
    const midPoint = (marker + nextMarker) / 2;
    for (let j = 0; j < colourBoundaries.length - 1; j++) {
      const colourBoundaryStart = colourBoundaries[j];
      const colourBoundaryEnd = colourBoundaries[j + 1];
      if (marker >= colourBoundaryStart && marker < colourBoundaryEnd) {
        const fractionAlongColourGradient =
          (midPoint - colourBoundaryStart) / (colourBoundaryEnd - colourBoundaryStart);
        colours.push(
          findColourAlongGragient(
            colourGradient[colourBoundaryStart],
            colourGradient[colourBoundaryEnd],
            fractionAlongColourGradient
          )
        );
      }
    }
  }
  return colours;
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default { substitution, replace };

/*
 * This function creates reference areas for a chart based on the provided legend
 * configuration and histogram keys. It calculates the boundaries of each area,
 * generates labels, and applies styling based on the legend items.
 */
export const getReferenceAreasFromLegend = (
  legendConfig: LegendConfig,
  histogramKeys: string[]
) => {
  const refAreas = [];
  for (let i = 0; i < legendConfig.items.length - 1; i++) {
    const areaStart = legendConfig.items[i].categoryStart;
    const areaEnd = legendConfig.items[i + 1].categoryStart;
    // The bin edges should align with the categories defined by the legend config
    const x1 = histogramKeys.find((k) => parseFloat(k.split('_')[0]) === areaStart);
    const x2 =
      i === legendConfig.items.length - 1
        ? histogramKeys.find((k) => parseFloat(k.split('_')[1]) === areaEnd)
        : histogramKeys.find((k) => parseFloat(k.split('_')[0]) === areaEnd);
    refAreas.push({
      x1,
      x2,
      label: ({ viewBox }) => {
        const { x, y, width } = viewBox;
        return (
          <text
            x={x + width - 4}
            y={y}
            fill={legendConfig.items[i + 1].color}
            fontSize={10}
            textAnchor="start"
            dominantBaseline="hanging"
            transform={`rotate(90, ${x + width - 6}, ${y + 4})`}
          >
            {legendConfig.items[i].label}
          </text>
        );
      },
      fill: legendConfig.items[i + 1].color,
      fillOpacity: 0.2,
    });
  }
  return refAreas;
};

/**
 * Rounds a number up to a specified number of significant figures
 */
export const roundUpToSignificantFigures = (num: number, sigFigs: number): number => {
  if (num === 0) {
    return 0;
  }

  const magnitude = Math.floor(Math.log10(Math.abs(num))) + 1;
  const scale = Math.pow(10, magnitude - sigFigs);

  return Math.ceil(num / scale) * scale;
};
