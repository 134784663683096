/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import { format } from 'd3-format';
import React from 'react';

import WidgetTooltip from '../../../../components/widget/tooltip';
import { IPlace } from '../../../../modules/places/model';
import { IWidgetConfig } from '../../../../modules/widget/model';
import { calculateTotal, formatYearObject } from '../../../../utils/widget';
import { formatKM2, formatPercentage, replace } from '../../utils';
import { DeforestationMetric } from './model';

interface DeforestationConfig {
  metric: DeforestationMetric;
}

export const CONFIG = {
  parse: ({ metric }: DeforestationConfig, params, widgetConfig: IWidgetConfig, place: IPlace) => {
    if (!metric?.year_data) {
      return {
        noData: true,
        chart: [],
        template: '',
      };
    }

    const { year_data, area_km2 } = metric;

    const numberKeys = Object.keys(year_data).map(Number);
    const startYear = Math.min(...numberKeys);
    const endYear = Math.max(...numberKeys);

    const totalLoss: number = calculateTotal(year_data);

    const years = formatYearObject(year_data);

    const { sentence } = widgetConfig;

    const loss_total_perc = totalLoss / area_km2;
    const loss_total_perc_format = formatPercentage(loss_total_perc);

    return {
      chart: years,
      template: replace(
        sentence.default,
        {
          location: place.name,
          start_year: startYear,
          end_year: endYear,
          first_year: startYear - 1,
          loss_total_area: `${formatKM2(totalLoss)} km²`,
          loss_total_perc: `${format(loss_total_perc_format)(loss_total_perc)}`,
        },
        {},
        {
          className: 'text-bold',
        }
      ),
      config: {
        xKey: 'year',
        margin: { top: 10, right: 10, left: 40, bottom: 5 },
        yKeys: {
          bars: {
            loss: {
              maxBarSize: 100,
              fill: '#D74E8C',
            },
          },
        },
        xAxis: {
          axisLine: {
            stroke: '#CCC',
            strokeWidth: 1,
          },
          angle: -45,
          dx: -10,
          dy: 10,
          tick: { fontSize: 11, fill: '#FFF', fontWeight: 500 },
          interval: 0,
        },
        yTitle: 'Tree Cover Loss (km²)',
        yAxis: {
          axisLine: {
            stroke: '#CCC',
            strokeWidth: 1,
          },
        },
        unitFormat: formatKM2,
        cartesianGrid: {
          vertical: false,
          strokeDasharray: '6 6',
        },
        tooltip: {
          cursor: false,
          content: (
            <WidgetTooltip
              settings={[
                { label: 'Year:', key: 'year' },
                {
                  label: 'Loss:',
                  key: 'loss',
                  suffix: ' km²',
                  format: formatKM2,
                },
              ]}
            />
          ),
        },
      },
    };
  },
};

export default CONFIG;
