/*
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *  https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import FileSaver from 'file-saver';
import IconAlert from 'mdi-material-ui/AlertOutline';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DropdownItem, Input, MuiSelect, QUERIES, TitleHero } from '@marapp/earth-shared';

import { useAuth0 } from '../../auth/auth0';
import { STAC_PROVIDER_VALUE } from '../../config';
import { useLayers } from '../../fetchers';
import { IPlace } from '../../modules/places/model';
import ExportService from '../../services/ExportService';
import { LayerRestrictionWarning } from '../layer-restriction-warning';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
  },
  header: {
    backgroundColor: theme.palette.grey['600'],
  },
  loadingIndicator: {
    position: 'absolute',
    top: theme.spacing(2.5), // align with select arrow
    right: theme.spacing(5),
  },
  scrollContainer: {
    flex: '1 1 auto',
    overflow: 'auto',
  },
  infoContainer: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
}));

interface IProps {
  place: Partial<IPlace>;
  onCancel?: () => void;
  groups?: string[];
}

const EXPORT_EXTENSIONS = {
  TIF: 'tif',
  PNG: 'png',
};

const EXPORT_TYPES = [
  { name: 'PNG', value: EXPORT_EXTENSIONS.PNG, className: 'marapp-qa-downloadpng' },
  { name: 'GeoTIFF', value: EXPORT_EXTENSIONS.TIF, className: 'marapp-qa-downloadgeotiff' },
];

export function ClipLayer(props: IProps) {
  const { onCancel, place, groups } = props;
  const { id, name, organization } = place;
  const [saveError, setSaveError] = useState('');
  const [childLayers, setChildLayers] = useState([]);
  const [restrictedChildLayers, setRestrictedChildLayers] = useState([]);
  const { t } = useTranslation();
  const { selectedGroup } = useAuth0();
  const { handleSubmit, formState, control, watch, setValue } = useForm({
    mode: 'all',
    defaultValues: {
      primaryLayer: null,
    },
  });
  const { data: restrictedLayers, isValidating } = useLayers(
    QUERIES.LAYER.getPrimaryFiltered('', {
      licenseRestriction: true,
    })
  );
  const classes = useStyles();
  const { isDirty, isValid, isSubmitting } = formState;
  const selectedPrimaryLayer = watch('primaryLayer');
  const selectedChildLayer = watch('childLayer');
  const selectedExportType = watch('exportType');

  // When primaryLayer changes set child layers
  useEffect(() => {
    let childLayers = [];
    let restrictedChildLayers = [];
    if (selectedPrimaryLayer?.references) {
      childLayers = selectedPrimaryLayer.references.filter(
        (childLayer) =>
          !childLayer.licenseRestriction && childLayer.provider === STAC_PROVIDER_VALUE
      );
      restrictedChildLayers = selectedPrimaryLayer.references.filter(
        (childLayer) => childLayer.licenseRestriction || childLayer.provider !== STAC_PROVIDER_VALUE
      );
    }
    setChildLayers(childLayers);
    setRestrictedChildLayers(restrictedChildLayers);
  }, [selectedPrimaryLayer]);

  // clear errors when form changes
  useEffect(() => {
    setSaveError('');
  }, [selectedPrimaryLayer, selectedChildLayer, selectedExportType]);

  // unable to make layers dropdown required otherwise
  const isValidCustom =
    isValid &&
    selectedExportType &&
    selectedPrimaryLayer &&
    (childLayers.length || restrictedChildLayers.length ? selectedChildLayer : true);

  const isLoadingRestrictedLayers = isValidating && !restrictedLayers?.length;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`${classes.root} marapp-qa-cliplayer`}>
      <Box mb={1}>
        <Paper className={classes.header} elevation={4} square={true}>
          <Box p={2}>
            <TitleHero title={name} subtitle={organization} extra={t('Collection')} />
          </Box>
        </Paper>
      </Box>

      <div className={classes.scrollContainer}>
        <Paper elevation={3}>
          <Box p={2}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Box position="relative">
                  <Controller
                    as={MuiSelect}
                    name="primaryLayer"
                    className="marapp-qa-primarylayers"
                    label={t('Select layer for download')}
                    placeholder={t('search layers')}
                    fetcher={useLayers}
                    query={(search) => QUERIES.LAYER.getForExport(search)}
                    control={control}
                    renderOption={(option) => (
                      <DropdownItem
                        title={option.name}
                        subtitle={selectedGroup?.length > 1 && option.organization}
                      />
                    )}
                  />

                  {isLoadingRestrictedLayers && (
                    <Box className={classes.loadingIndicator}>
                      <CircularProgress size={16} />
                    </Box>
                  )}
                </Box>
              </Grid>

              {!!childLayers.length && (
                <Grid item={true} xs={12}>
                  <Controller
                    as={MuiSelect}
                    key={childLayers.toString()}
                    name="childLayer"
                    className="marapp-qa-childlayers"
                    label={t('Select layer for download')}
                    placeholder={t('search layers')}
                    options={childLayers}
                    control={control}
                    getOptionLabel={(option) => option.name}
                  />
                </Grid>
              )}

              <Grid item={true} xs={12} className={classes.infoContainer}>
                <LayerRestrictionWarning
                  restrictedLayers={restrictedLayers}
                  restrictedChildLayers={restrictedChildLayers}
                />

                {restrictedChildLayers?.length && !childLayers?.length ? (
                  <Box mt={1}>
                    <Typography
                      className="marapp-qa-no-child-layers"
                      variant="caption"
                      color="error"
                    >
                      <IconAlert />
                      &nbsp;
                      {t('No child layers available for download')}
                    </Typography>
                  </Box>
                ) : null}
              </Grid>

              <Grid item={true} xs={12}>
                <Typography component="label">{t('Select a file type for download')}</Typography>

                <Controller
                  name="exportType"
                  control={control}
                  defaultValue={EXPORT_TYPES[0].value}
                  as={
                    <RadioGroup row={true}>
                      {EXPORT_TYPES.map((type) => (
                        <FormControlLabel
                          control={<Radio />}
                          className={type.className}
                          disabled={EXPORT_TYPES.length < 2}
                          key={type.name}
                          label={type.name}
                          value={type.value}
                        />
                      ))}
                    </RadioGroup>
                  }
                />
              </Grid>

              {selectedExportType === EXPORT_EXTENSIONS.TIF && (
                <Grid item={true} xs={12}>
                  <Typography component="label">{t('Output projection')}</Typography>

                  <Controller
                    name="exportCRS"
                    control={control}
                    defaultValue={'EPSG:4326'}
                    as={Input}
                    InputProps={{
                      endAdornment: 'CRS',
                    }}
                  />
                </Grid>
              )}

              {selectedExportType === EXPORT_EXTENSIONS.PNG && (
                <Grid item={true} xs={12}>
                  <Typography component="label">{t('Maximum size')}</Typography>

                  <Controller
                    name="exportSize"
                    control={control}
                    defaultValue={'1024'}
                    as={Input}
                    InputProps={{
                      endAdornment: 'px',
                    }}
                  />
                </Grid>
              )}

              {saveError && (
                <Grid item={true} xs={12}>
                  <Typography className="marapp-qa-formerror" color="error">
                    {saveError}
                  </Typography>
                </Grid>
              )}

              <Grid item={true} xs={12} container={true} spacing={1}>
                <Grid item={true}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="secondary"
                    className="marapp-qa-actiondownload"
                    disabled={!isValidCustom || isSubmitting || !isDirty}
                    endIcon={isSubmitting && <CircularProgress size={16} />}
                  >
                    {isSubmitting ? t('Downloading') : t('Download')}
                  </Button>
                </Grid>

                <Grid item={true}>
                  <Button className="marapp-qa-actioncancel" size="large" onClick={onCancel}>
                    {t('Cancel')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </div>
    </form>
  );

  async function onSubmit(values) {
    const { exportType, primaryLayer, exportCRS, exportSize } = values;
    const { childLayer: selectedLayer = primaryLayer } = values;
    const { decodeConfig, decodeFunction } = selectedLayer;

    try {
      if (decodeConfig || decodeFunction) {
        throw new Error(t('Selected layers are currently not supported'));
      }

      const { data } = await ExportService.exportLayerForLocation(selectedLayer.id, id, {
        exportType,
        exportCRS,
        exportSize,
        group: groups.join(','),
      });

      const rawResponse = await fetch(data.downloadURL);

      if (rawResponse.status === 200) {
        const blob = await rawResponse.blob();
        FileSaver.saveAs(blob, `${selectedLayer.name}.${exportType}`);
      } else {
        throw new Error(t('Could not download layer. Area too large'));
      }
    } catch (e) {
      if (e?.status === 403) {
        setSaveError(t('Could not download layer. Forbidden layer export'));
      } else if (e?.status === 404) {
        setSaveError(t('Could not download layer. No data available for location'));
      } else if (e?.status === 413) {
        setSaveError(
          t('Could not download layer. Area too large') +
            '. ' +
            t(
              'Select a smaller place, or refer to the layer’s information box to download data from the original source.'
            )
        );
      } else if (e?.message) {
        setSaveError(e.message);
      } else if (e?.data.errors) {
        setSaveError(e.data.errors[0].detail);
      } else {
        setSaveError(t('Something went wrong'));
      }
    }
  }
}
