/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { replace } from 'redux-first-router';
import Link from 'redux-first-router-link';

import { Spinner } from '@marapp/earth-shared';

import { useAuth0 } from '../../auth/auth0';
import { PUBLIC_URL } from '../../config';
import ProfileService from '../../services/ProfileService';
import { APP_LOGO } from '../../theme';

const BackgroundImage = require('../../images/blur.jpg');

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  logo: {
    borderBottom: 'none !important',
    '& img': {
      maxHeight: theme.spacing(7),
    },
  },
}));

enum ChangeEmailStates {
  VERIFIED = 'Email change successful. Please sign in with your new email to continue with your update.',
  PENDING = 'Change email. Please sign in with your original account email to continue with your update.',
  ERROR = 'It looks like something went wrong with this verification link, it might have expired, or it’s possible you’re using a bad URL.',
}

export default function ChangeEmailComponent() {
  const { login, isAuthenticated } = useAuth0();
  const [errorPage, setErrorPage] = useState('');

  useEffect(() => {
    const fn = async () => {
      const hashParameter = window.location.hash;
      const hashQuery = hashParameter.split('#')[1];
      const params = new URLSearchParams(hashQuery);

      const accessToken = params.get('access_token');
      const error = params.get('error');

      const message: string = error ? ChangeEmailStates.ERROR : ChangeEmailStates.PENDING;

      if (!isAuthenticated) {
        // preserve path, query and hash params when redirecting;
        const target = window.location.href.replace(window.location.origin, '');
        // save target URL to redirect to after login;
        return login({ appState: { targetUrl: target }, emailState: message });
      }

      if (accessToken) {
        try {
          const response = await ProfileService.changeEmailConfirmation({ accessToken });
          if (response && response?.data?.success) {
            // Auth0 sessions are reset when a user’s email or password changes;
            // force a re-login if email change request successful;
            return login({
              appState: { targetUrl: '/profile' },
              emailState: ChangeEmailStates.VERIFIED,
            });
          }
        } catch (err) {
          setErrorPage(ChangeEmailStates.ERROR);
        }
      } else if (error) {
        setErrorPage(message);
      } else {
        replace('/profile');
      }
    };
    fn();
  });

  if (errorPage) {
    return <ErrorPage error={errorPage} />;
  }

  return <Spinner size="large" />;
}

const ErrorPage = ({ error }) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.root} marapp-qa-change-email`}
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
      p={6}
    >
      <Paper elevation={3}>
        <Box maxWidth={375} p={3}>
          <Box mb={2} textAlign="center">
            <a href={`${PUBLIC_URL}earth`} className={classes.logo}>
              <img src={APP_LOGO.default} className="marapp-qa-logo" />
            </a>
          </Box>

          <Typography align="center" paragraph={true} variant="subtitle1">
            Oops!
          </Typography>

          <Typography align="center" paragraph={true} variant="body2">
            {error}
          </Typography>

          <Box mt={3}>
            <Button
              className="marapp-qa-gotoprofile"
              component={Link}
              fullWidth={true}
              size="large"
              to={{ type: 'PROFILE' }}
              variant="outlined"
            >
              return to profile
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
