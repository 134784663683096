import capitalize from '@material-ui/core/utils/capitalize';
import { format } from 'd3-format';
import sum from 'lodash/sum';

import { IMetricTableData } from '../config';

export const handler = (metricData: any): IMetricTableData[] => {
  return metricData.map(metricInfo => {
    const {
      id,
      location: { name },
      metric: { data },
    } = metricInfo;

    const totalArea = data && sum(Object.values(data));

    return {
      id,
      location: name,
      ...(data &&
        Object.keys(data).reduce((acc, key) => {
          const area = data[key];
          const percentage = area / totalArea;
          const displayKey = capitalize(key.replace('_', ' '));
          acc[displayKey] = {
            children: {
              area: {
                value: area,
                displayValue: `${format(',.0f')(area)} km²`,
              },
              '%': {
                value: percentage,
                displayValue: `${format('.2%')(percentage)}`,
              },
            },
          };
          return acc;
        }, {})),
    };
  });
};
