import { format } from 'd3-format';
import findLast from 'lodash/findLast';
import sumBy from 'lodash/sumBy';

import { IMetricTableData } from '../config';

export const BIODIVERSITY_INTACTNESS_CATEGORIES = {
  0: 'Very Low',
  20: 'Low',
  40: 'Medium',
  60: 'High',
  80: 'Very High',
};

export const handler = (metricData: any): IMetricTableData[] => {
  return metricData.map((metricInfo) => {
    const {
      id,
      location: { name },
      metric,
    } = metricInfo;

    const totalMeasuredArea = sumBy(Object.keys(metric), (key) =>
      key.includes('percentile') ? metric[key] : 0
    );

    const metricCategoricalData = Object.keys(metric).reduce((acc, key) => {
      if (key.includes('percentile')) {
        const percentileValue = key.split('_')[1];

        const metricCategory = findLast(Object.keys(BIODIVERSITY_INTACTNESS_CATEGORIES), (k) => {
          return parseInt(percentileValue, 10) >= parseInt(k, 10);
        });

        if (!acc[BIODIVERSITY_INTACTNESS_CATEGORIES[metricCategory]]) {
          acc[BIODIVERSITY_INTACTNESS_CATEGORIES[metricCategory]] = 0;
        }

        acc[BIODIVERSITY_INTACTNESS_CATEGORIES[metricCategory]] += metric[key];
      }

      return acc;
    }, {});

    Object.keys(metricCategoricalData).forEach((key) => {
      const metricValue = metricCategoricalData[key];
      const percentage = metricValue / totalMeasuredArea;
      const area = metric.area_km2 * percentage;

      metricCategoricalData[key] = {
        children: {
          area: {
            value: area,
            displayValue: `${format(',.0f')(area)} km²`,
          },
          '%': {
            value: percentage,
            displayValue: `${format('.2%')(percentage)}`,
          },
        },
      };
    });

    return {
      id,
      location: name,
      ...metricCategoricalData,
    };
  });
};
