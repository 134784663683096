/*
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *  https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */
import { ConfigInterface, SWRInfiniteConfigInterface } from 'swr';

import { IQueryMany, IQueryOne, TRANSFORM, useFetchMany, useFetchOne } from '@marapp/earth-shared';

import { useAuth0 } from '../auth/auth0';
import { MAP_API_URL } from '../config';

export function useLocations(query: IQueryMany, swrOptions?: SWRInfiniteConfigInterface) {
  const { selectedGroup } = useAuth0();
  const specificQuery: IQueryMany = {
    group: selectedGroup.join(),
    ...query,
  };

  return useFetchMany(`${MAP_API_URL}/locations`, specificQuery, {
    swrOptions,
    transformResponse: TRANSFORM.groupFilters,
  });
}

export function useLocation(idOrSlug: string, query: IQueryOne, swrOptions?: ConfigInterface) {
  return useFetchOne(`${MAP_API_URL}/locations/${idOrSlug}`, query, { swrOptions });
}
