/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import debounce from 'lodash/debounce';
import compose from 'lodash/fp/compose';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { animated, Keyframes } from 'react-spring/renderprops.cjs';
import {
  Legend,
  LegendItemButtonInfo,
  LegendItemButtonOpacity,
  LegendItemButtonRemove,
  LegendItemButtonVisibility,
  LegendItemTimeStep,
  LegendItemToolbar,
  LegendItemTypes,
  LegendListItem,
} from 'vizzuality-components';

import { Html } from '@marapp/earth-shared';
import { addBlankToLinks } from '@marapp/earth-shared/src/utils';

import { recordLayerInfoButtonClick } from '../../../analytics/gtag';
import { MAP_SIDEBAR_WIDTH, MAP_SIDEBAR_WIDTH_WIDE } from '../../../config';
import { IPopup } from '../../../modules/map/selectors';
import { EarthRoutes, IRouter } from '../../../modules/router/model';
import LegendItemGroup from './legend-item-group';
import TEMPLATES from './templates';

// Creates a spring with predefined animation slots
const LegendWrapper: any = Keyframes.Spring({
  open: { x: MAP_SIDEBAR_WIDTH, opacity: 1, delay: 0 },
  openW: { x: MAP_SIDEBAR_WIDTH_WIDE, opacity: 1 },
  close: { x: 0, opacity: 1, delay: 100 },
});

const styles = (theme) => {
  const LEGEND_BACKGROUND = theme.palette.background.paper;
  const LEGEND_TEXT_COLOR = theme.palette.text.primary;
  return {
    '@global': {
      '.rc-tooltip': {
        color: theme.palette.text.primary,
        '& .rc-tooltip-inner': {
          backgroundColor: `${theme.palette.grey['600']} !important`,
          boxShadow: `${theme.shadows[10]} !important`,
        },
        '& .rc-tooltip-arrow': {
          borderTopColor: `${theme.palette.grey['600']} !important`,
        },
        '& .rc-slider-mark': {
          left: 0,
          width: '100%',
        },
        '& *': {
          color: `${theme.palette.text.primary} !important`,
        },
      },
    },
    root: {
      backgroundColor: theme.palette.grey['600'],
      position: 'absolute',
      left: theme.spacing(3),
      bottom: theme.spacing(5),
      right: 'auto',
      width: 350,
      zIndex: theme.zIndex.speedDial,
      '& .vizzuality__c-legend-list': {
        paddingLeft: '0 !important',
      },

      '& .vizzuality__c-legend-map': {
        backgroundColor: LEGEND_BACKGROUND,
        border: 'none',
        borderRadius: '0 !important',
        '& .vizzuality__close-legend': {
          '&.vizzuality__-active': {
            backgroundColor: LEGEND_BACKGROUND,
            padding: theme.spacing(1, 0),
          },
          '& .vizzuality__legend-title': {
            ...theme.typography.body2,
            background: 'none',
            color: LEGEND_TEXT_COLOR,
            textTransform: 'uppercase',
            width: '100%',
          },
        },
        '& .vizzuality__toggle-legend': {
          backgroundColor: LEGEND_BACKGROUND,
          borderRadius: 0,
          right: 0,
          transform: 'none',
          '& svg': {
            fill: LEGEND_TEXT_COLOR,
          },
        },
        '& .vizzuality__open-legend.vizzuality__-active': {
          maxHeight: 'calc(100vh - 138px) !important',
          '& .vizzuality__toggle-legend': {
            borderBottom: `1px solid ${theme.palette.divider}`,
            boxShadow: theme.shadows[3],
          },
        },
      },

      '& .vizzuality__c-legend-item': {
        backgroundColor: LEGEND_BACKGROUND,
        color: LEGEND_TEXT_COLOR,
        fill: LEGEND_TEXT_COLOR,
        fontFamily: theme.typography.fontFamily,

        '& .vizzuality__c-legend-item-toolbar': {
          margin: 0,
        },

        '& .vizzuality__c-legend-item-types': {
          backgroundColor: LEGEND_BACKGROUND,
        },

        '& .vizzuality__legend-item-header': {
          alignItems: 'center',
          marginBottom: theme.spacing(1),

          '& > h3': {
            ...theme.typography.subtitle2,
            color: theme.palette.text.secondary,
            margin: 0,
          },
        },

        '& .vizzuality__legend-item-container': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },

        '& .vizzuality__c-legend-type-choropleth': {
          '& > li': {
            flex: '1 0 0',
          },
          '& .vizzuality__name': {
            fontSize: 12,
            letterSpacing: '0px',
            textTransform: 'none',
          },
        },

        '& .vizzuality__c-legend-handler': {
          top: theme.spacing(2),
        },

        '& .vizzuality__vertical': {
          paddingLeft: '0 !important',
        },

        '& .vizzuality__icon-square': {
          borderRadius: 0,
          marginTop: 2,
          marginRight: theme.spacing(1),
        },

        '& .vizzuality__custom-icon, .vizzuality__icon-circle, .vizzuality__icon-line, .vizzuality__icon-triangle':
          {
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
          },

        '& .vizzuality__c-legend-type-basic > ul.vizzuality__vertical li': {
          marginBottom: theme.spacing(1),
        },

        '& .vizzuality__legend-gradient-icon .vizzuality__name, .vizzuality__c-legend-type-gradient .vizzuality__name':
          {
            color: LEGEND_TEXT_COLOR,
            ...theme.typography.caption,
          },

        '& .vizzuality__c-legend-type-gradient ul': {
          paddingLeft: '0 !important',
          '& .vizzuality__name': {
            fontSize: 12,
            letterSpacing: '0px',
            textTransform: 'none',
          },
        },

        '& .vizzuality__c-legend-type-gradient .vizzuality__icon-gradient': {
          marginTop: 5,
        },

        '& .vizzuality__c-legend-item .vizzuality__legend-info > *': {
          marginTop: 0,
        },

        '& .vizzuality__c-legend-handler > svg': {
          fill: LEGEND_TEXT_COLOR,
        },

        '& .vizzuality__name': {
          ...theme.typography.caption,
          color: LEGEND_TEXT_COLOR,
          textTransform: 'uppercase',
        },

        '& .select--input': {
          background: 'transparent',
          color: LEGEND_BACKGROUND,
        },

        '& .vizzuality__c-timestep': {
          '& > ,vizzuality__range-slider': {
            '& .vizzuality__range.vizzuality__can-play': {
              width: 'calc(100% - 40px)',
              marginLeft: theme.spacing(2),
            },
          },

          '& .rc-slider-mark-text': {
            ...theme.typography.caption,
            color: theme.palette.text.primary,
          },

          '& .rc-slider-disabled': {
            backgroundColor: 'transparent',
          },

          '& .rc-slider-handle': {
            '&:active': {
              boxShadow: 'none',
            },

            '&.rc-slider-handle-1': {
              '&:after': {
                content: `""`,
                width: 4,
                height: 4,
                backgroundColor: LEGEND_BACKGROUND,
                position: 'absolute',
                borderRadius: 4,
                top: 'calc(50% - 2px)',
                left: 'calc(50% - 2px)',
              },
            },

            '&:nth-last-child(2)': {
              '&:after': {
                content: `""`,
                width: 4,
                height: 4,
                backgroundColor: LEGEND_BACKGROUND,
                position: 'absolute',
                borderRadius: 4,
                top: 'calc(50% - 2px)',
                left: 'calc(50% - 2px)',
              },
            },
          },

          '& .vizzuality__player-btn': {
            height: theme.spacing(3),
            width: theme.spacing(3),
            border: `1px solid ${theme.palette.text.secondary}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            backgroundColor: LEGEND_BACKGROUND,
            '& svg': {
              position: 'relative',
              height: theme.spacing(1),
              width: theme.spacing(1),
              fill: LEGEND_TEXT_COLOR,
            },
            '&:hover': {
              background: theme.palette.action.hover,
              borderColor: LEGEND_TEXT_COLOR,
            },
            '&.-playing': {
              background: LEGEND_TEXT_COLOR,
              borderColor: LEGEND_TEXT_COLOR,
              '& svg': {
                left: 0,
                fill: LEGEND_BACKGROUND,
              },
            },
          },
        },
      },
    },
    chip: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey['600'],
      marginRight: theme.spacing(1),
    },
  };
};

export interface ILegend {
  classes?: any;
  layerGroups?: any[];
  setLayerVisibility?: (data: any) => void;
  setLayerOrder?: (data: any) => void;
  setLayerOpacity?: (data: any) => void;
  setLayerGroupCurrent?: (data: any) => void;
  setLayerInfo?: (data: any) => void;
  toggleLayer?: any;
  setLayerSettings?: (data: any) => void;
  open?: boolean;
  router?: IRouter;
  t?: (arg: any) => any;
  theme?: any;
  popup?: IPopup;
  titleUpperCase?: boolean;
}

class LegendComponent extends React.PureComponent<ILegend & IPopup, any> {
  public legendRef: any;

  public onChangeOpacity = debounce((l, opacity, slug) => {
    const { setLayerOpacity } = this.props;
    setLayerOpacity({ slug, dataset: { id: l.dataset }, opacity });
  }, 250);

  public onRemoveLayer = debounce((layer) => {
    const { toggleLayer } = this.props;
    toggleLayer(layer);
  }, 250);

  public constructor(props: any) {
    super(props);
    this.legendRef = React.createRef();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<ILegend>) {
    const popupKeys = Object.keys(nextProps.popup);
    if (popupKeys && popupKeys.length && this.legendRef) {
      this.legendRef.current?.setState((prevState: any) => ({ ...prevState, expanded: false }));
    }
  }

  public onChangeInfo = (info, slug) => {
    const { setLayerInfo } = this.props;
    setLayerInfo({ slug, info });
    recordLayerInfoButtonClick(slug);
  };

  public onChangeVisibility = (l, visibility, slug) => {
    const { setLayerVisibility } = this.props;
    setLayerVisibility({ slug, dataset: { id: l.dataset }, visibility });
  };

  public onChangeOrder = (datasetIds) => {
    const { setLayerOrder } = this.props;
    setLayerOrder({ datasetIds });
  };

  public onChangeCurrent = (l, current, slug) => {
    const { setLayerGroupCurrent } = this.props;

    setLayerGroupCurrent({ slug, current });
  };

  public onChangeLayerDate = (dates, layer) => {
    const { setLayerSettings } = this.props;
    const { slug, decodeConfig } = layer;

    setLayerSettings({
      slug,
      settings: {
        ...(decodeConfig && {
          decodeParams: {
            startDate: dates[0],
            endDate: dates[1],
            trimEndDate: dates[2],
          },
        }),
        ...(!decodeConfig && {
          params: {
            startDate: dates[0],
            endDate: dates[1],
          },
        }),
      },
    });
  };

  public getState = () => {
    const { open, router } = this.props;
    const selectedOpen = [
      EarthRoutes.LOCATION,
      EarthRoutes.COLLECTION,
      EarthRoutes.ELSA_NEW,
      EarthRoutes.ELSA_EDIT,
    ].includes(router.type);

    if (open) {
      if (selectedOpen) {
        return 'openW';
      }
      return 'open';
    } else {
      return 'close';
    }
  };

  public render() {
    const { classes, layerGroups, t, theme, titleUpperCase = false } = this.props;

    const state = this.getState();

    return (
      <LegendWrapper native={true} state={state}>
        {({ x, ...props }) => (
          <animated.div
            className={`${classes.root} marapp-qa-legend`}
            style={{
              transform: x.interpolate((x) => `translate3d(${x}px,0,0)`),
              ...props,
            }}
          >
            <Legend
              ref={this.legendRef}
              title={t('Legend')}
              maxHeight="65vh"
              onChangeOrder={this.onChangeOrder}
            >
              {layerGroups.map((layerGroup: any, i) => {
                layerGroup.description = addBlankToLinks(layerGroup.description);

                const opacityLabelText = `${t('Opacity')}${
                  layerGroup.visibility
                    ? layerGroup.opacity ?? null !== null
                      ? ` (${Math.round(layerGroup.opacity * 100)}%)`
                      : ''
                    : ` (${t('disabled')})`
                }`;

                return (
                  <LegendListItem
                    index={i}
                    key={layerGroup.slug}
                    layerGroup={layerGroup}
                    toolbar={
                      <LegendItemToolbar>
                        {'layerGroup.description' && (
                          <LegendItemButtonInfo tooltipText={t('Dataset info')} />
                        )}
                        <LegendItemButtonOpacity
                          tooltipText={opacityLabelText}
                          trackStyle={{
                            background: theme.palette.secondary.main,
                          }}
                          handleStyle={{
                            background: theme.palette.secondary.main,
                          }}
                          railStyle={{
                            background: theme.palette.divider,
                          }}
                        />
                        <LegendItemButtonVisibility
                          tooltipText={t(layerGroup.visibility ? 'Hide layer' : 'Show layer')}
                        />
                        <LegendItemButtonRemove tooltipText={t('Remove layer')} />
                      </LegendItemToolbar>
                    }
                    onChangeInfo={(l) => this.onChangeInfo(true, layerGroup.slug)}
                    onChangeVisibility={(l, visibility) =>
                      this.onChangeVisibility(l, visibility, layerGroup.slug)
                    }
                    onChangeOpacity={(l, opacity) =>
                      this.onChangeOpacity(l, opacity, layerGroup.slug)
                    }
                    onRemoveLayer={(l) => {
                      this.onRemoveLayer(l);
                    }}
                  >
                    {!!TEMPLATES[layerGroup.slug] &&
                      React.createElement(TEMPLATES[layerGroup.slug])}

                    {!!TEMPLATES[layerGroup.legendType] &&
                      React.createElement(TEMPLATES[layerGroup.legendType])}

                    <LegendItemGroup
                      onChangeCurrent={(l, current) =>
                        this.onChangeCurrent(l, current, layerGroup.slug)
                      }
                    />

                    <LegendItemTypes />
                    {!!layerGroup.layers[0].decodeParams && (
                      <LegendItemTimeStep
                        defaultStyles={{
                          handleStyle: {
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.29)',
                            border: '0px',
                            zIndex: 2,
                          },
                          railStyle: { backgroundColor: '#d6d6d9' },
                          dotStyle: { visibility: 'hidden', border: '0px' },
                        }}
                        handleChange={this.onChangeLayerDate}
                      />
                    )}

                    <Dialog
                      open={!!layerGroup.info}
                      onClose={() => this.onChangeInfo(false, layerGroup.slug)}
                      className="marapp-qa-layerinfo"
                    >
                      <DialogTitle>
                        <div>
                          {titleUpperCase ? layerGroup?.name.toUpperCase() : layerGroup?.name}
                        </div>
                        {layerGroup.tags?.map((t) => (
                          <Chip
                            label={t}
                            size="small"
                            className={`marapp-qa-tagspill ${classes.chip}`}
                          />
                        ))}
                      </DialogTitle>

                      <DialogContent>
                        <Html
                          className="layer-info--html translate-content"
                          html={layerGroup?.description}
                        />
                      </DialogContent>

                      <DialogActions>
                        <Button
                          size="large"
                          className="marapp-qa-modalclose"
                          onClick={() => this.onChangeInfo(false, layerGroup.slug)}
                        >
                          {t('Close')}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </LegendListItem>
                );
              })}
            </Legend>
          </animated.div>
        )}
      </LegendWrapper>
    );
  }
}

export default compose(withStyles(styles), withTheme, withTranslation())(LegendComponent);
