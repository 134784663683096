import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import IconCircleSmall from 'mdi-material-ui/CircleSmall';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MAP_ADMIN_URL, SUPER_ADMIN_WORKSPACE } from '../../config';

interface IProps {
  group: any;
  hasAdminAccess: boolean;
  hasMultipleGroups: boolean;
  selectedGroups: any[];
  onOrgChange: (org: any) => void;
}

const useListItemStyles = makeStyles(theme => ({
  organisationListItem: isAdmin => {
    return {
      paddingRight: isAdmin ? 100 : theme.spacing(2),
    };
  },
  organisationCheckbox: {
    padding: 0,
  },
  organisationSelectionIconContainer: {
    minWidth: theme.spacing(5),
  },
  subheader: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const WorkspaceListItem = (props: IProps) => {
  const { group, hasAdminAccess, hasMultipleGroups, selectedGroups, onOrgChange } = props;
  const isSuperAdmin = group.name === SUPER_ADMIN_WORKSPACE;
  const { t } = useTranslation(['translation', 'admin']);
  const classes = useListItemStyles(hasAdminAccess);

  const listItemProps = {
    classes: {
      root: classes.organisationListItem,
    },
    dense: true,
    key: group.name,
    ...(isSuperAdmin
      ? {
          component: 'a',
          href: `${MAP_ADMIN_URL}${group.name}`,
        }
      : {
          onClick: () => hasMultipleGroups && onOrgChange(group.name),
        }),
  };

  return (
    <ListItem {...listItemProps} button={true}>
      {hasMultipleGroups && (
        <ListItemIcon className={classes.organisationSelectionIconContainer}>
          {!isSuperAdmin && (
            <Checkbox
              checked={!!selectedGroups.find(x => x === group.name)}
              classes={{
                root: classes.organisationCheckbox,
              }}
            />
          )}
        </ListItemIcon>
      )}
      <ListItemText
        primary={`${isSuperAdmin ? t('Platform Admin') : group.description}`}
        secondary={
          !isSuperAdmin && (
            <span className={classes.subheader}>
              {t('Places')} ({group.locations})<IconCircleSmall />
              {t('Datasets')} ({group.layers})
            </span>
          )
        }
      />
      {hasAdminAccess && (
        <ListItemSecondaryAction>
          <Button
            component="a"
            className={`marapp-qa-admin-link marapp-qa-admin-link-${group.name}`}
            href={`${MAP_ADMIN_URL}${group.name}`}
            variant="outlined"
            size="small"
          >
            {t('Admin')}
          </Button>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
