/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import React from 'react';
import { connect } from 'react-redux';

import { setLayersSearch, setLayersSearchOpen, toggleLayer } from '../../modules/layers/actions';
import { setMapStyle } from '../../modules/map/actions';
import { setPlacesSearch } from '../../modules/places/actions';
import { setSidebarPanel, setSidebarPanelExpanded } from '../../modules/sidebar/actions';
import Layers from './Layers';

export default connect(
  (state: any, props: any) => ({
    ...state.sidebar,
    layers: state.layers,

    mapStyle: state.map.mapStyle,
    locationName: props.locationName,
    locationOrganization: props.locationOrganization,
  }),
  {
    toggleLayer,
    setMapStyle,
    setSidebarPanel,
    setSidebarPanelExpanded,
    setLayersSearch,
    setPlacesSearch,
    setLayersSearchOpen,
  }
)(Layers);
