import { format } from 'd3-format';

import { IMetricTableData } from '../config';

const getDataValue = (value) => {
  return {
    value,
    displayValue: value ? `${format(',.2f')(value)}` : '-',
  };
};

export const handler = (metricData: any): IMetricTableData[] => {
  /**
   * 1. Extract all the possible years from received data
   */
  const years = new Set();

  metricData.forEach((metricInfo) => {
    const {
      metric: { year_data },
    } = metricInfo;

    year_data && year_data.forEach((data) => years.add(data.year));
  });

  return metricData.map((metricInfo) => {
    const {
      id,
      location: { name },
      metric: { year_data },
    } = metricInfo;

    const mappedMetricInfo = {
      id,
      location: name,
    };

    years.forEach((year: string) => {
      const value = year_data.find((data) => data.year === year);

      mappedMetricInfo[year] = getDataValue(value?.norm);
    });

    return mappedMetricInfo;
  });
};
