import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { BooleanButton, SliderInput } from '@marapp/earth-shared';

interface IProps {
  title: string;
  subtitle?: string;
  budgetName: string;
  budgetMin: number;
  budgetMax: number;
  budgetStart: number;
  lockName?: string;
  lockStart?: boolean;
  lockMin?: number;
  lockMax?: number;
}

export const ELSAManage = (props: IProps) => {
  const {
    title,
    subtitle,
    lockStart,
    lockName,
    budgetName,
    budgetMin,
    budgetMax,
    budgetStart,
    lockMin,
    lockMax,
  } = props;
  const { control } = useFormContext();

  const slug = title.toLowerCase().replace(' ', '-');
  const lockWatch = lockName ? useWatch({ name: [lockName] }) : false;
  const lockVal = lockWatch ? lockWatch[lockName] : false;

  return (
    <>
      <Typography variant="subtitle2" color="textSecondary">
        {title}
      </Typography>
      <Box px={2} py={1}>
        {lockName && (
          <Grid item={true} xs={true}>
            <Typography variant="body2" component={'span'}>
              {subtitle}
              &nbsp;
              <Controller
                className={`marapp-qa-elsa-${slug}-lock`}
                control={control}
                defaultValue={lockStart}
                name={lockName}
                render={({ onChange }) => (
                  <BooleanButton onChange={onChange} defaultValue={lockStart} />
                )}
              />
            </Typography>
          </Grid>
        )}
        <Grid item={true}>
          <Controller
            className={`marapp-qa-elsa-${slug}-budget`}
            control={control}
            defaultValue={budgetStart}
            name={budgetName}
            render={({ onChange }) => (
              <SliderInput
                onChange={onChange}
                min={lockVal && lockMin ? lockMin : budgetMin}
                max={lockVal && lockMax ? lockMax : budgetMax}
                start={budgetStart}
                step={0.1}
              />
            )}
          />
        </Grid>
      </Box>
    </>
  );
};
