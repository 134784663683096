import { format } from 'd3-format';
// import findLast from 'lodash/findLast';

import { IMetricTableData, IMetricTableDataValue } from '../config';

// const HUMAN_FOOTPRINT_CATEGORIES = {
//   0: 'No Pressure (Wild)',
//   1: 'Low Pressure',
//   3: 'Moderate Pressure',
//   6: 'High Pressure',
//   18: 'Very High Pressure',
// };

const getDataValue = (value: number): IMetricTableDataValue => {
  // const metricCategory = findLast(Object.keys(HUMAN_FOOTPRINT_CATEGORIES), (k) => {
  //   return value && Math.round(value) >= parseInt(k, 10);
  // });

  return {
    value,
    displayValue: value ? `${format('.0f')(value)}%` : '-',
  };
};

export const handler = (metricData: any): IMetricTableData[] => {
  /**
   * 1. Extract all the possible years from received data
   */
  const years = new Set();

  metricData.forEach((metricInfo) => {
    const {
      metric: { year_data },
    } = metricInfo;

    year_data && Object.keys(year_data).forEach((year) => years.add(year));
  });

  return metricData.map((metricInfo) => {
    const {
      id,
      location: { name },
      metric: { year_data },
    } = metricInfo;

    const mappedMetricInfo = {
      id,
      location: name,
    };

    if (year_data) {
      years.forEach((year: string) => {
        const value = year_data[year].mean;

        mappedMetricInfo[year] = getDataValue(value);
      });
    }

    return mappedMetricInfo;
  });
};
