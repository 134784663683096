/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import Link from 'redux-first-router-link';

import { useAuth0 } from '../../auth/auth0';
import { MAP_APP_NAME, MAP_ENABLE_PUBLIC_ACCESS } from '../../config';
import ProfileService from '../../services/ProfileService';

const BackgroundImage = require('../../images/blur.jpg');

enum EmailStates {
  INACTIVE = 'Inactive',
  PENDING = 'Pending',
  DONE = 'Done',
  ERROR = 'Error',
}

interface VerifyEmailState {
  type: EmailStates;
  text: string;
  message?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
}));

const VerifyEmail = () => {
  const { logout, isEmailVerified } = useAuth0();
  const classes = useStyles();

  return (
    <Box
      className={`${classes.root} marapp-qa-verify-email`}
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
      p={6}
    >
      <Paper elevation={3}>
        <Box maxWidth={375} p={3}>
          {isEmailVerified ? <VerifiedEmailTemplate /> : <VerifyEmailTemplate logout={logout} />}
        </Box>
      </Paper>
    </Box>
  );
};

const VerifyEmailTemplate = ({ logout }) => {
  const [emailState, setEmailState] = useState<VerifyEmailState>({
    type: EmailStates.INACTIVE,
    text: 'Send email verification Link',
  });

  const handleResendEmail = async () => {
    setEmailState({ type: EmailStates.PENDING, text: 'sending email validation' });

    try {
      await ProfileService.resendEmailConfirmation();
      setEmailState({ type: EmailStates.DONE, text: 'email sent' });
    } catch (error) {
      setEmailState({
        type: EmailStates.ERROR,
        text: 'something went wrong',
        message: error?.data.errors[0].detail,
      });
    }
  };

  return (
    <>
      <Typography align="center" paragraph={true} variant="subtitle1">
        Verify Your Email
      </Typography>
      <Typography align="center" paragraph={true} variant="body2">
        Please follow the instructions in the message sent to your email account to complete the
        sign up process. You will be able to see content when an owner assigns you to an
        organization.
      </Typography>
      {MAP_ENABLE_PUBLIC_ACCESS && (
        <>
          <Typography align="center" variant="body2" paragraph={true}>
            Until then you can still browse content as a public user, or you may visit our{' '}
            <a href="https://github.com/natgeosociety/marapp-frontend/blob/master/ABOUT.md">
              about page.
            </a>
          </Typography>
        </>
      )}
      <Box mb={1} mt={3}>
        <Button
          className="marapp-qa-logout"
          color="secondary"
          fullWidth={true}
          onClick={(e) => logout()}
          size="large"
          variant="contained"
        >
          {MAP_ENABLE_PUBLIC_ACCESS ? `View ${MAP_APP_NAME} as a Public User` : 'Logout'}
        </Button>
      </Box>
      <Button
        disabled={emailState.type.includes(EmailStates.DONE)}
        className="marapp-qa-resendemail"
        fullWidth={true}
        onClick={(e) => handleResendEmail()}
        size="large"
        variant="outlined"
        endIcon={emailState.type.includes(EmailStates.PENDING) && <CircularProgress size={16} />}
      >
        {emailState.text}
      </Button>
      {emailState.message && (
        <Box mt={1}>
          <Typography align="center" color="error">
            {emailState.message}
          </Typography>
        </Box>
      )}
    </>
  );
};

const VerifiedEmailTemplate = () => {
  return (
    <>
      <Typography align="center" paragraph={true} variant="subtitle1">
        Email verified!
      </Typography>
      <Typography align="center" paragraph={true} variant="body2">
        Your email address has been successfully verified. To access your account, please sign in
        with your email.
      </Typography>

      <Box mt={3}>
        <Button
          className="marapp-qa-returnhome"
          color="secondary"
          component={Link}
          fullWidth={true}
          size="large"
          to={{ type: 'EARTH' }}
          variant="contained"
        >
          Return to home
        </Button>
      </Box>
    </>
  );
};

export default VerifyEmail;
