/**
 * Record gtac event
 * @param eventName
 * @param params
 */
export const recordEvent = (eventName: string, params: object = {}) => {

  // Making this a no-op temporarily until we have agreed policy
  // for getting user consent on collection analytics.
  // TODO: When we reactivate this method, update trackGtagEvent method in packages/earth-map/src/utils/index.tsx 

  // if (typeof window !== 'undefined' && window.gtag) {
  //   window.gtag('event', eventName, JSON.parse(JSON.stringify(params)));
  // } else {
  //   console.log('gtag not found');
  // }
};

/**
 * Get language on google supported format
 */
export const getLanguage = () => {
  const supportedLanguages = {
    en: 'en_gb',
    es: 'es_mx',
    fr: 'fr_fr',
    pt: 'pt_pt',
    ru: 'ru_ru',
  };
  const languagePrefix = sessionStorage.getItem('lang') || 'en';

  return supportedLanguages[languagePrefix] || 'en_gb';
};

/**
 * Record page view event
 * @param pageTitle
 */
export const recordPageView = (pageTitle: string) => {
  recordEvent('page_view', {
    page_location: window.location.href,
    language: getLanguage(),
    page_title: pageTitle,
    user_agent: navigator.userAgent,
  });
};

/**
 * Record search event
 * @param searchTerm
 */
export const recordSearch = (searchTerm: string) => {
  recordEvent('search', {
    search_term: searchTerm,
  });
};

/**
 * Record select content event
 * @param contentType
 * @param contentId
 */
export const recordSelectContent = (contentType: string, contentId: string) => {
  recordEvent('select_content', {
    content_type: contentType,
    content_id: contentId,
  });
};

interface Item {
  item_id: string;
  item_name: string;
  item_category: string;
}

/**
 * Record view items event
 * @param itemId
 * @param itemName
 * @param itemCategory
 */
export const recordViewItems = (items: Item[]) => {
  recordEvent('select_item', {
    items,
  });
};

/**
 * Record type selection on main Tabs using gtag custom events
 * @param type
 */
export const recordTypeSelection = (type: string) => {
  recordEvent('select_type', {
    type,
  });
};

/**
 * Record search places event
 * @param searchTerm
 */
export const recordSearchPlaces = (searchTerm: string) => {
  recordEvent('search_places', {
    search_term: searchTerm,
  });
};

/**
 * Record search layers event
 * @param searchTerm
 */
export const recordSearchLayers = (searchTerm: string) => {
  recordEvent('search_layers', {
    search_term: searchTerm,
  });
};

/**
 * Record filter selection event
 * @param type
 * @param filters
 */
export const recordFilterSelection = (type: string, filters: string[]) => {
  recordEvent('select_filters', {
    type,
    filters,
  });
};

/**
 * Record select search result event
 * @param type
 * @param filter
 * @param result
 */
export const recordSelectSearchResult = (type: string, filter: string, result: string) => {
  recordEvent('select_search_result', {
    type,
    filter,
    result,
  });
};

/**
 * Record show on map event
 * @param type
 * @param slug
 */
export const recordShowOnMap = (place: string, layer: string) => {
  recordEvent('show_on_map', {
    place,
    layer,
  });
};

/**
 * Record download event
 * @param type
 * @param location
 * @param layer
 */
export const recordDownload = (type: string, location: string, layer: string) => {
  recordEvent('download', {
    type,
    location,
    layer,
  });
};

/**
 * Record info button click event
 * @param place
 * @param slug
 * @param name
 */
export const recordInfoButtonClick = (place: string, slug: string, name: string) => {
  recordEvent('info_button_click', {
    id: place,
    slug,
    name,
  });
};

/**
 * Record layer info button click event
 * @param slug
 */
export const recordLayerInfoButtonClick = (slug: string) => {
  recordEvent('layer_info_button_click', {
    slug,
  });
};
