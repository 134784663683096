import {
  biodiversityHandler,
  humanFootprintHandler,
  landUseHandler,
  lulcHandler,
  modisEviHandler,
  protectedAreasHandler,
  templateMetricsHandler,
  terrestrialCarbonHandler,
  treeLossHandler,
} from './handlers';

export const METRIC_HANDLERS = {
  'biodiversity-intactness': biodiversityHandler,
  'human-footprint': humanFootprintHandler,
  'land-use': landUseHandler,
  'modis-evi': modisEviHandler,
  'protected-areas': protectedAreasHandler,
  'terrestrial-carbon': terrestrialCarbonHandler,
  'tree-loss': treeLossHandler,
  'io-lulc': lulcHandler,
  // 'modis-fire', 'human-impact'
};

export interface IMetricTableDataValue {
  value?: string | number;
  displayValue?: string | number;
  children?: object;
}

export interface IMetricTableData {
  id: string;
  location?: string;
  [key: string]: IMetricTableDataValue | any;
}

export const mapMetricDataToTableDataHandler = (
  metricSlug: string,
  metricData: any
): IMetricTableData[] => {
  const handler = METRIC_HANDLERS[metricSlug] || templateMetricsHandler;

  return handler(metricData);
};
