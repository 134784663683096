/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { Children } from 'react';
import Link from 'redux-first-router-link';

import { MAP_APP_NAME } from '../../config';
import { APP_LOGO } from '../../theme';

interface IProps {
  title?: String;
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.grey['600'],
    },
    logo: {
      '& img': {
        maxHeight: theme.spacing(6),
        width: 'auto',
      },
    },
  };
});

const SimpleHeader = (props: IProps) => {
  const { title } = props;
  const classes = useStyles();

  const logo = (
    <Link
      className={`${classes.logo} marapp-qa-logo`}
      to={{
        type: 'EARTH',
      }}
    >
      <img src={APP_LOGO.default} alt={MAP_APP_NAME} />
    </Link>
  );

  return (
    <Box className={classes.root} py={1} px={2}>
      <Grid
        container={true}
        className="marapp-qa-simple-header"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid item={true} className={`${classes.logo} marapp-qa-logo`}>
          {logo}
        </Grid>
        <Grid item={true}>
          <h3>{title}</h3>
        </Grid>
      </Grid>
    </Box>
  );
};

export { SimpleHeader };
