/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

/**
 * Check if filters contain any values
 */
export const hasFilters = (filters: IFilters): boolean => {
  return countFilters(filters) !== 0;
};

/**
 * Count the number of selected filters
 */
export const countFilters = (filters: IFilters): number => {
  return Object.keys(filters).reduce((acc, current) => {
    const val = filters[current];
    return acc + val.length;
  }, 0);
};

/**
 * Strips keys that have empty arrays
 */
export const cleanFilters = (filters: IFilters): IFilters => {
  return Object.keys(filters)
    .filter((key) => filters[key].length)
    .reduce((acc, key) => {
      acc[key] = filters[key];
      return acc;
    }, {});
};

/**
 * Get the total sum of entities the current filter should return
 */
export const countSelectedFiltersEntities = (
  selectedFilters: any,
  availableFilters: any
): number => {
  return Object.keys(selectedFilters).reduce((acc, current) => {
    const val = selectedFilters[current];

    const count = val?.reduce((acc, current) => {
      const currentFilterEntities = availableFilters?.type?.find(
        (filter) => filter.value === current
      );

      return acc + (currentFilterEntities ? currentFilterEntities.count : 0);
    }, 0);
    return acc + count;
  }, 0);
};

export interface IFilters {
  [key: string]: string[] | boolean[];
}
