/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MAP_SIDEBAR_WIDTH, MAP_SIDEBAR_WIDTH_WIDE } from '../../config';

interface IProps {
  open?: boolean;
  selectedOpen?: boolean;
  resetLayersActive?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
  },
}));

const LayerConfigError = (props: IProps) => {
  const { open, resetLayersActive, selectedOpen } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const containerStyle = {
    marginLeft: 0,
  };

  if (open) {
    containerStyle.marginLeft = MAP_SIDEBAR_WIDTH;

    if (selectedOpen) {
      containerStyle.marginLeft = MAP_SIDEBAR_WIDTH_WIDE;
    }
  }

  return (
    <Box py={6} px={7} className={`${classes.root} marapp-qa-layer-error`} style={containerStyle}>
      <Box mb={2}>
        <Typography variant="h5" color="error">
          OOPS!
        </Typography>
      </Box>

      <Grid container={true}>
        <Grid item={true} xs={6} lg={4}>
          <Paper elevation={3}>
            <CardContent>
              <Typography paragraph={true}>
                {t('Invalid layer configuration specified')}.
              </Typography>

              <Box display="flex" justifyContent="center">
                <Button
                  className="marapp-qa-reset-filters"
                  onClick={resetLayersActive}
                  size="large"
                  variant="outlined"
                >
                  {t('Reset layers')}
                </Button>
              </Box>
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LayerConfigError;
