import findLast from 'lodash/findLast';

import { IMetricTableData, IMetricTableDataValue } from '../config';

interface ITerrestrialCarbonMetric {
  carbon_total_t: string | number;
  soil_total_t: string | number;
  carbon_soil_total_t: string | number;
}

interface IMagnitudeUnit {
  unit: string;
  scalePow: number;
}

const MAGNITUDE_UNITS: IMagnitudeUnit[] = [
  { unit: 't', scalePow: 0 },
  { unit: 'kt', scalePow: 1 },
  { unit: 'Mt', scalePow: 2 },
  { unit: 'Gt', scalePow: 3 },
  { unit: 'Tt', scalePow: 4 },
];

const MAGNITUDE_SCALE = 1000;

const formatValue = (value, unit: IMagnitudeUnit, decimals = 2): string => {
  if (value === 0) {
    return `0 ${unit}`;
  }

  const calculatedValue = value / Math.pow(MAGNITUDE_SCALE, unit.scalePow);
  const dm = calculatedValue < 1 ? 3 : 2;
  const displayValue = parseFloat(calculatedValue.toFixed(dm));

  return `${displayValue} ${unit.unit}`;
};

const getDataValue = (value: string | number, unit: IMagnitudeUnit): IMetricTableDataValue => ({
  displayValue: formatValue(value, unit),
  value,
});

const getMaxMetricFieldValue = (value: string, fieldName: string, acc: any) => {
  if (parseFloat(value) > acc[fieldName]) {
    acc[fieldName] = parseFloat(value);
  }
};

const getMaxMetricFieldsValues = (metricData: any): ITerrestrialCarbonMetric => {
  return metricData.reduce(
    (acc, metricInfo) => {
      const {
        metric: { carbon_total_t, soil_total_t, carbon_soil_total_t },
      } = metricInfo;

      getMaxMetricFieldValue(carbon_total_t, 'carbon_total_t', acc);
      getMaxMetricFieldValue(soil_total_t, 'soil_total_t', acc);
      getMaxMetricFieldValue(carbon_soil_total_t, 'carbon_soil_total_t', acc);

      return acc;
    },
    {
      carbon_total_t: 0,
      soil_total_t: 0,
      carbon_soil_total_t: 0,
    }
  );
};

const getMaxMetricFieldUnit = (metricValue: ITerrestrialCarbonMetric, fieldName: string) => {
  const value = metricValue[fieldName];

  return value
    ? findLast(MAGNITUDE_UNITS, ({ scalePow }) => {
        return value / Math.pow(MAGNITUDE_SCALE, scalePow) > 1;
      })
    : MAGNITUDE_UNITS[0];
};

export const handler = (metricData: any): IMetricTableData[] => {
  const maxMetricValue = getMaxMetricFieldsValues(metricData);
  const carbon_total_unit = getMaxMetricFieldUnit(maxMetricValue, 'carbon_total_t');
  const soil_unit = getMaxMetricFieldUnit(maxMetricValue, 'soil_total_t');
  const carbon_soil_total_unit = getMaxMetricFieldUnit(maxMetricValue, 'carbon_soil_total_t');

  return metricData.map((metricInfo) => {
    const {
      id,
      location: { name },
      metric: { carbon_total_t, soil_total_t, carbon_soil_total_t },
    } = metricInfo;

    return {
      id,
      location: name,
      carbon_total_t: getDataValue(carbon_total_t, carbon_total_unit),
      soil_total_t: getDataValue(soil_total_t, soil_unit),
      carbon_soil_total_t: getDataValue(carbon_soil_total_t, carbon_soil_total_unit),
    };
  });
};
