/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import React from 'react';

interface ILegendItemGroupComponent {
  activeLayer?: any;
  onChangeCurrent?: (activeLayer: any, value: any) => void;
}

class LegendItemGroupComponent extends React.PureComponent<ILegendItemGroupComponent> {
  public static propTypes = {};

  public static defaultProps = {
    activeLayer: {},
  };

  public onChangeCurrent = (e) => {
    const { activeLayer, onChangeCurrent } = this.props;
    onChangeCurrent(activeLayer, e.currentTarget.value);
  };

  public render() {
    const { activeLayer } = this.props;

    const { current, source, references } = activeLayer;
    const { type } = source;

    if (type !== 'group') {
      return null;
    }

    if (!references) {
      return null;
    }

    return (
      <Box mb={1}>
        <RadioGroup className="marapp-qa-layergroup">
          {references.map((l) => {
            const currentActive = current || references[0].id;
            const checked = currentActive === l.id;

            return (
              <FormControlLabel
                key={l.id}
                value={l.id}
                id={`layer-group-${l.id}`}
                control={<Radio size="small" />}
                onChange={this.onChangeCurrent}
                label={<Typography variant="body2">{l.name}</Typography>}
                checked={checked}
              />
            );
          })}
        </RadioGroup>
      </Box>
    );
  }
}

export default LegendItemGroupComponent;
