import { connect } from 'react-redux';

import { setMapLabels, setMapRoads, setMapStyle } from '../../../modules/map/actions';
import MapControls from './component';

export default connect(
  (state: any) => ({
    mapLabels: state.map.mapLabels,
    mapRoads: state.map.mapRoads,
    mapStyle: state.map.mapStyle,
  }),
  {
    setMapLabels,
    setMapRoads,
    setMapStyle,
  }
)(MapControls);
