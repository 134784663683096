import forOwn from 'lodash/forOwn';

export const parseObject = (str) => {
  const out = {};
  str = str.replace(/[{|}]/g, '').split(', ');
  str.forEach((v) => {
    const [key, value] = v.split('=>');
    out[key] = parseFloat(value);
  });
  return out;
};

export const formatYearObject = (data) => {
  const temp = [];
  forOwn(data, (value, key) => {
    return temp.push({ loss: value, year: parseInt(key, 10) });
  });
  return temp;
};
export const calculateTotal = (data): number =>
  Object.values(data).reduce((a: number, b: number) => a + b) as number;
