/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from '@marapp/earth-shared';

import { getParams } from '../../../../../modules/layers/utils';

interface ILegendYearDatePicker {
  activeLayer: {
    slug: any;
    params: any;
    decodeParams: any;
    timelineParams: any;
    source: any;
  };
  setLayerSettings: (values: any) => void;
  setLayerTimelineCurrent: (values: any) => void;
  layers: any;
  references: any;
}

interface ISelectedLayer {
  id?: string;
  paramsConfig?: { year?: string };
  decodeConfig?: { values: any };
  source?: any;
}

const YearDatePickerLegendComponent = (props: ILegendYearDatePicker) => {
  const [selectedLayer, setSelectedLayer] = useState<ISelectedLayer>();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [decodeValues, setDecodeValues] = useState({} as any);
  const { t } = useTranslation();

  const { layers, activeLayer } = props;

  const { references } = layers[0];

  const options = references.map((y) => {
    return {
      label: y.name,
      value: y.id,
    };
  });

  useEffect(() => {
    setSelectedLayer(references[0]);
  }, [references]);

  useEffect(() => {
    const { decodeParams } = activeLayer;

    decodeParams &&
      decodeParams.startDate &&
      decodeParams.startDate !== startDate &&
      setStartDate(decodeParams.startDate);

    decodeParams &&
      decodeParams.trimEndDate &&
      decodeParams.trimEndDate !== endDate &&
      setEndDate(decodeParams.trimEndDate);
  }, [activeLayer.decodeParams]);

  useEffect(() => {
    const { activeLayer, setLayerTimelineCurrent } = props;

    if (!!selectedLayer) {
      const { slug } = activeLayer;

      const { paramsConfig, decodeConfig } = selectedLayer;

      setLayerTimelineCurrent({
        slug,
        current: selectedLayer.id,
        year: parseInt(paramsConfig[0].year, 10),
        settings: selectedLayer,
      });

      const decodedValues = getParams(decodeConfig.values, {});

      setDecodeValues(decodedValues);
      setStartDate(decodedValues.startDate);
      setEndDate(decodedValues.endDate);
    }
  }, [selectedLayer]);

  const onChange = (value) => {
    setSelectedLayer(references.filter((ref) => ref.id === value)[0]);
  };

  const onDateChange = (value, who) => {
    const { activeLayer, setLayerTimelineCurrent } = props;

    const { slug, decodeParams } = activeLayer;

    const { paramsConfig, source } = selectedLayer;

    setLayerTimelineCurrent({
      slug,

      current: selectedLayer.id,

      year: parseInt(paramsConfig[0].year, 10),
      settings: {
        ...source,
        ...{
          decodeConfig: {
            ...decodeParams,
            [who]: moment(value).format('YYYY-MM-DD'),
            ...(who === 'trimEndDate' && {
              endDate: moment(value).format('YYYY-MM-DD'),
            }),
          },
        },
      },
    });
  };

  const datePickerProps = {
    autoOk: true, // auto close picker when selecting a date
    disableToolbar: true, // hide top typography
    labelFunc: (date) => moment(date).format('DD MMM'), // format displayed date
    margin: 'dense',
    variant: 'inline',
  };

  return (
    !!startDate && (
      <Box mb={1}>
        <Grid container={true} spacing={1} className="marapp-qa-legendyeardate">
          <Grid item={true} xs={12}>
            <Select
              className="marapp-qa-active-layer"
              onChange={(e) => onChange(e)}
              options={options}
              fullWidth={true}
              margin="dense"
            />
          </Grid>

          <Grid item={true} xs={12} sm={6}>
            <DatePicker
              className="marapp-qa-input-start-date"
              label={t('From')}
              value={startDate}
              onChange={(date) => onDateChange(date, 'startDate')}
              minDate={startDate < decodeValues.startDate ? startDate : decodeValues.startDate}
              maxDate={decodeValues.endDate}
              {...datePickerProps}
            />
          </Grid>

          {!!endDate && (
            <Grid item={true} xs={12} sm={6}>
              <DatePicker
                className="marapp-qa-input-end-date"
                label={t('To')}
                value={endDate}
                onChange={(date) => onDateChange(date, 'trimEndDate')}
                minDate={decodeValues.startDate}
                maxDate={endDate > decodeValues.endDate ? endDate : decodeValues.endDate}
                {...datePickerProps}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    )
  );
};

export default YearDatePickerLegendComponent;
