import { format } from 'd3-format';

import { IMetricTableData } from '../config';

const getDataValue = (value) => {
  return {
    value,
    displayValue: isNaN(value) ? '-' : `${format(',.0f')(value)} km²`,
  };
};

export const handler = (metricData: any): IMetricTableData[] => {
  return metricData
    ?.filter((metric) => metric?.metric && Object.keys(metric?.metric).length)
    ?.map((metricInfo) => {
      const {
        id,
        location: { name },
        metric,
      } = metricInfo;

      return {
        id,
        location: name,
        ...Object.keys(metric).reduce((acc, current) => {
          // @ts-ignore
          const key = isNaN(current) ? current : `${format(',.2f')(current)} km²`;

          return {
            ...acc,
            [key]: getDataValue(metric[current]),
          };
        }, {}),
      };
    });
};
