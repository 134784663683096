import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    flex: '1 1 auto',
    overflow: 'auto',
  },
}));

interface IProps {
  fixedContent: React.ReactElement;
  children: React.ReactElement;
}

const SidebarLayoutSearch = (props: IProps) => {
  const classes = useStyles();
  return (
    <>
      {props.fixedContent}
      <div className={classes.root}>{props.children}</div>
    </>
  );
};

export default SidebarLayoutSearch;
