/*
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *  https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ToggleIcon from 'material-ui-toggle-icon';
import CompareArrows from 'mdi-material-ui/CompareHorizontal';
import IconEye from 'mdi-material-ui/Eye';
import IconEyeOff from 'mdi-material-ui/EyeOff';
import IconInfo from 'mdi-material-ui/InformationOutline';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    background: '#616161',
    maxWidth: 800,
  },
}));

export const LayerVisibilityToggle = React.forwardRef((props: any, ref: any) => {
  const { previewLayer, show, setShow, setLayersActive, setMapLayer } = props;

  return (
    <IconButton
      className={`marapp-qa-action-elsa-layer-visibility-toggle icon-show-${show}`}
      size={'small'}
      onClick={() => {
        if (show === previewLayer.slug) {
          setShow(false);
          setLayersActive([]);
        } else {
          setMapLayer(previewLayer);
          setLayersActive([previewLayer.slug]);
          setShow(previewLayer.slug);
        }
      }}
    >
      <ToggleIcon on={show === previewLayer.slug} onIcon={<IconEyeOff />} offIcon={<IconEye />} />
    </IconButton>
  );
});

export const LayerInfoToggle = React.forwardRef((props: any, ref: any) => {
  const { title, text, citation } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton
        className={`marapp-qa-action-elsa-layer-info-toggle icon-show-hover`}
        size={'small'}
        onClick={handleOpen}
      >
        <IconInfo />
      </IconButton>
      <Dialog
        open={!!open}
        onClose={handleClose}
        className="marapp-qa-elsa-layer-info"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          <Typography>{text}</Typography>
          <br />
          <Typography variant="body2">{citation}</Typography>
          <br />
        </DialogContent>
      </Dialog>
    </>
  );
});

export const LayerSwapToggle = React.forwardRef((props: any, ref: any) => {
  const classes = useStyles();

  const { tooltipText } = props;

  return (
    <Tooltip
      className={`marapp-qa-action-elsa-layer-swap-toggle icon-show-hover`}
      classes={{ tooltip: classes.tooltip }}
      title={tooltipText}
      placement="bottom-start"
      interactive={true}
    >
      <IconButton size={'small'}>
        <CompareArrows />
      </IconButton>
    </Tooltip>
  );
});
