import { format } from 'd3-format';
import React from 'react';

import WidgetTooltip from '../../../../components/widget/tooltip';
import { IPlace } from '../../../../modules/places/model';
import { IWidgetConfig } from '../../../../modules/widget/model';
import { findColoursAlongGradient, replace, roundUpToSignificantFigures } from '../../utils';
import { BiodiversityIntactnessMetric } from './model';

interface BioConfig {
  metric: BiodiversityIntactnessMetric;
}

export const CONFIG = {
  parse: ({ metric }: BioConfig, params, widgetConfig: IWidgetConfig, place: IPlace) => {
    if (!metric?.mean) {
      return {
        noData: true,
        chart: [],
        template: '',
      };
    }

    const { legendConfig, sentence } = widgetConfig;
    const { mean, histogram, area_km2, nodata_area_km2 } = metric;

    const areaWithDataKm2 = area_km2 - nodata_area_km2;

    const meanPercentage = Math.round(mean * 100);

    // Determine the boundaries that define the categories from the widget's legend config
    const legendItems = legendConfig.items.sort((a, b) => a.categoryStart - b.categoryStart);
    const maxBII = legendItems[legendItems.length - 1].categoryStart;

    const histogramBins = Object.keys(histogram);

    // Determine the colors of the bars in the bar chart and tooltip colors
    const colours = findColoursAlongGradient(
      histogramBins.map((k) => parseFloat(k.split('_')[0])),
      maxBII,
      legendConfig.items.reduce((acc, legendItem) => {
        acc[legendItem.categoryStart] = legendItem.color;
        return acc;
      }, {})
    );

    const chart = histogramBins.map((bin, index) => {
      return {
        bin,
        areaKm2: histogram[bin],
        fill: colours[index],
        itemColor: false,
        index,
      };
    });

    return {
      chart,
      template: replace(
        sentence.default,
        {
          location: place.name,
          year: metric.year,
          intact_perc: `${format('.2r')(meanPercentage)}%`,
          lost_perc: `${format('.2r')(100 - meanPercentage)}%`,
        },
        {},
        {
          className: 'text-bold',
        }
      ),
      config: {
        type: 'bar',
        height: 300,
        margin: { top: 20, right: 10, left: 50, bottom: 10 },
        xKey: 'bin',
        yTitle: 'Area (km²)',
        yKeys: {
          bars: {
            areaKm2: {},
          },
        },
        yAxis: {
          domain: [0, roundUpToSignificantFigures(Math.max(...Object.values(histogram)), 1)],
          axisLine: {
            stroke: '#CCC',
            strokeWidth: 1,
          },
        },
        xAxis: {
          axisLine: {
            stroke: '#CCC',
            strokeWidth: 1,
          },
          label: {
            value: 'Biodiversity Intactness (%)',
            dy: 16,
            fill: '#FFF',
          },
          dx: -10,
          tick: { fontSize: 11, fill: '#FFF', fontWeight: 500 },
          interval: histogramBins.length / 20 - 1 > 0 ? histogramBins.length / 20 - 1 : 0,
          tickFormatter: (tick) => {
            const [binStart, binEnd] = tick.split('_').map((b) => Math.round(parseFloat(b) * 100));
            return binEnd === maxBII ? binEnd : binStart;
          },
        },
        tooltip: {
          cursor: false,
          content: ({ payload, active }) => {
            if (active && payload && payload.length > 0) {
              const settings = [];
              const binIndex: number = payload[0].payload.index;
              const [binStart, binEnd] = histogramBins[binIndex]
                .split('_')
                .map((k) => format(',.1f')(parseFloat(k) * 100));
              const area = payload[0].payload.areaKm2;

              settings.push({
                label: `Area with BII between ${binStart} - ${binEnd}%: ${format(',.0f')(
                  area
                )} km² (${format(',.2f')((area * 100) / areaWithDataKm2)}%)`,
                color: area > 0 ? colours[binIndex] : null,
              });

              return <WidgetTooltip hideZeros={false} payload={payload} settings={settings} />;
            }
          },
        },
      },
    };
  },
};

export default CONFIG;
