import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Switch from '@material-ui/core/Switch';
import debounce from 'lodash/debounce';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import IconUp from 'mdi-material-ui/ChevronUp';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExpansionIcon, Menu } from '@marapp/earth-shared';

import { APP_BASEMAPS } from '../../../theme';
import MapControlListItem from './list-item';

interface IMapControlsProps {
  className?: string;
  mapLabels: any;
  mapRoads: any;
  mapStyle: any;
  setMapLabels: (any) => void;
  setMapRoads: (any) => void;
  setMapStyle: (any) => void;
}

const useStyles = makeStyles(theme => ({
  loadingIndicator: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0,0,0,0.25)',
  },
  mapBackground: {
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  mapControlsButton: {
    pointerEvents: 'all',
    backgroundColor: theme.palette.grey['600'],
    color: theme.palette.text.primary,
    height: 30,
    '&:hover': {
      background: theme.palette.grey['500'],
    },
  },
  mapControlsPopover: {
    '& .MuiMenu-paper': 'none',
    '& .MuiMenu-list': {
      pointerEvents: 'all',
    },
  },
  switch: {
    marginLeft: theme.spacing(-1),
  },
}));

const MapControls = (props: IMapControlsProps) => {
  const {
    className,
    mapLabels,
    mapRoads,
    mapStyle,
    setMapLabels,
    setMapRoads,
    setMapStyle,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [loadingMapStyle, setLoadingMapStyle] = useState(null);

  useEffect(() => {
    setTimeout(() => setLoadingMapStyle(null), 1000);
  }, [loadingMapStyle]);

  const popupState = usePopupState({ variant: 'popover', popupId: 'mapControlsMenu' });

  const isBaseMapSelected = slug => {
    return mapStyle === slug;
  };

  const onSelectBaseMap = slug => {
    if (!isBaseMapSelected(slug)) {
      setMapStyle(slug);
      setLoadingMapStyle(slug);
    }
  };

  const onSetLabels = () => {
    setMapLabels(!mapLabels);
  };

  const onSetRoads = () => {
    setMapRoads(!mapRoads);
  };

  const getOptionCommonProps = onClick => ({
    onClick: debounce(onClick, 200),
    disableCloseOnOptionClick: true,
  });

  return (
    <div className={`${className} marapp-qa-map-controls`}>
      <Button
        className={`${classes.mapControlsButton} marapp-qa-map-controls-toggle`}
        {...bindTrigger(popupState)}
        size="small"
        variant="contained"
        endIcon={<ExpansionIcon icon={IconUp} isActive={popupState.isOpen} />}
      >
        {t('Map Controls')}
      </Button>

      <Menu
        className={classes.mapControlsPopover}
        anchorOrigin={{
          vertical: theme.spacing(-1),
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        popupState={popupState}
        onBlur={null}
        options={[
          ...APP_BASEMAPS.map(({ background, id, name, slug }) => ({
            className: `marapp-qa-base-map marapp-qa-base-map-${name}`,
            selected: isBaseMapSelected(slug),
            label: (
              <MapControlListItem
                label={name}
                avatar={
                  <Avatar
                    className={classes.mapBackground}
                    style={{
                      backgroundImage: `url(${background})`,
                    }}
                    variant="rounded"
                  >
                    {loadingMapStyle === slug && (
                      <div className={classes.loadingIndicator}>
                        <CircularProgress color="secondary" size={16} />
                      </div>
                    )}
                  </Avatar>
                }
              />
            ),
            ...getOptionCommonProps(() => onSelectBaseMap(slug)),
          })),
          {
            className: 'marapp-qa-map-labels-toggle',
            label: (
              <MapControlListItem
                label={t('Labels')}
                avatar={
                  <Switch
                    className={`${classes.switch} marapp-qa-map-labels-toggle`}
                    checked={!!mapLabels}
                  />
                }
              />
            ),
            ...getOptionCommonProps(onSetLabels),
          },
          {
            className: 'marapp-qa-map-roads-toggle',
            label: (
              <MapControlListItem
                label={t('Roads')}
                avatar={
                  <Switch
                    className={`${classes.switch} marapp-qa-map-roads-toggle`}
                    checked={!!mapRoads}
                  />
                }
              />
            ),
            ...getOptionCommonProps(onSetRoads),
          },
        ]}
      />
    </div>
  );
};

export default MapControls;
